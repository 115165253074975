
import React, { createContext, useState } from 'react';

export const ChainzContext = createContext();
export const ChainzProvider = (props) => {
        const [chainz, setChainz] = useState([
                
                        
                
                
            // Roller Chains
            {
              id: 'cad-01',
              name: ['Type B1','Typ B1',],
              type: ['Roller Chain','Łańcuch Rolkowy',],
              img: ['cad-01-1',],
              clicked: false,
              models: [
                {
                  name: '08B-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '12.700', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '7.75', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '4.45', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '8.51', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '11.81', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '16.70', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '1820', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '0.70', 
                    },

                  ]
                },
                {
                  name: '10B-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '9.65', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.08', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '10.16', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.70', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '19.10', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2270', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '0.95', 
                    },

                  ]
                },
                {
                  name: '12B-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '11.68', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '12.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '22.40', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2950', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.25', 
                    },

                  ]
                },
                {
                  name: '16B-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '17.02', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '36.10', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5800', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.70', 
                    },

                  ]
                },
                {
                  name: '20B-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '19.56', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '10.19', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '26.40', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '41.60', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9500', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '3.60', 
                    },

                  ]
                },
                {
                  name: '24B-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '14.63', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '33.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '53.40', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '5.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '17000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '6.70', 
                    },

                  ]
                },
                {
                  name: '28B-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '30.99', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '15.90', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '27.94', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '64.60', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '7.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '20000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '8.30', 
                    },

                  ]
                },
                {
                  name: '32B-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '30.99', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '17.81', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '29.21', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '41.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '67.40', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '7.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '26000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '10.50', 
                    },

                  ]
                },
              ],
            },
            {
              id: 'cad-02',
              name: ['Type B2','Typ B2',],
              type: ['Roller Chain','Łańcuch Rolkowy',],
              img: ['cad-02-1',],
              clicked: false,
              models: [
                {
                  name: '08B-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '12.700', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '7.75', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '4.45', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '8.51', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '11.81', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '16.70', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '13.92', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '3180', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.35', 
                    },

                  ]
                },
                {
                  name: '10B-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '9.65', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.08', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '10.16', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.70', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '35.80', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '16.59', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4540', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.85', 
                    },

                  ]
                },
                {
                  name: '12B-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '11.68', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '12.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '42.20', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '19.46', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5900', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.50', 
                    },

                  ]
                },
                {
                  name: '16B-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '17.02', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '68.00', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '31.88', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '11000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '5.40', 
                    },

                  ]
                },
                {
                  name: '20B-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '19.56', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '10.19', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '26.40', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '77.20', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '36.45', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '18000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '7.20', 
                    },

                  ]
                },
                {
                  name: '24B-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '14.63', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '33.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '101.00', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '5.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '48.36', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '32400', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '13.50', 
                    },

                  ]
                },
                {
                  name: '28B-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '30.99', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '15.90', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '27.94', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '124.00', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '7.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '59.56', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '38100', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '16.60', 
                    },

                  ]
                },
                {
                  name: '32B-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '30.99', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '17.81', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '29.21', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '41.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '122.80', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '7.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '58.55', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '49500', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '21.00', 
                    },

                  ]
                },
              ],
            },
            {
              id: 'cad-03',
              name: ['Type B3','Typ B3',],
              type: ['Roller Chain','Łańcuch Rolkowy',],
              img: ['cad-03-1',],
              clicked: false,
              models: [
                {
                  name: '08B-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '12.700', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '7.75', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '4.45', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '8.51', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '11.81', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '45.10', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '13.92', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4540', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.00', 
                    },

                  ]
                },
                {
                  name: '10B-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '9.65', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.08', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '10.16', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.70', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '52.70', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '16.59', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '6810', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.80', 
                    },

                  ]
                },
                {
                  name: '12B-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '11.68', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '12.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '61.70', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '19.46', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8850', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '3.80', 
                    },

                  ]
                },
                {
                  name: '16B-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '17.02', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '99.90', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '31.88', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '16500', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '8.00', 
                    },

                  ]
                },
                {
                  name: '20B-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '19.56', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '10.19', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '26.40', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '113.70', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '36.45', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '27000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '11.00', 
                    },

                  ]
                },
                {
                  name: '24B-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '14.63', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '33.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '150.00', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '5.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '48.36', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '48500', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '21.00', 
                    },

                  ]
                },
                {
                  name: '28B-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '30.99', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '15.90', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '27.94', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '183.70', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '7.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '59.56', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '57100', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '25.00', 
                    },

                  ]
                },
                {
                  name: '32B-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '30.99', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '17.81', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '29.21', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '41.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '181.30', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '7.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '58.55', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '74300', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '32.00', 
                    },

                  ]
                },
              ],
            },

            {
              id: 'cad-04',
              name: ['Type A1','Typ A1',],
              type: ['Roller Chain','Łańcuch Rolkowy',],
              img: ['cad-04-1',],
              clicked: false,
              models: [
                {
                  name: '08A-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '12.700', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '3.96', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '3.96', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '7.95', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '17.80', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '17.80', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '1420', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '0.64', 
                    },

                  ]
                },
                {
                  name: '10A-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.880', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '9.53', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.08', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '10.16', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.80', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '20.70', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '2.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '2.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2220', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.05', 
                    },

                  ]
                },
                {
                  name: '12A-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '12.70', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.94', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '11.91', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '25.70', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '2.40', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '2.40', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '3180', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.51', 
                    },

                  ]
                },
                {
                  name: '16A-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '32.50', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5670', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.61', 
                    },

                  ]
                },
                {
                  name: '20A-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '19.00', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '40.20', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8850', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '3.90', 
                    },

                  ]
                },
                {
                  name: '24A-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '11.10', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '22.23', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '12700', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '5.74', 
                    },

                  ]
                },
                {
                  name: '28A-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '12.70', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '42.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '54.00', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '5.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '5.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '17240', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '7.22', 
                    },

                  ]
                },
                {
                  name: '32A-1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '31.75', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '14.27', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '28.58', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '64.00', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '6.40', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '6.40', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '22680', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '9.84', 
                    },

                  ]
                },

                  
              ],
            },
            {
              id: 'cad-05',
              name: ['Type A2','Typ A2',],
              type: ['Roller Chain','Łańcuch Rolkowy',],
              img: ['cad-05-1',],
              clicked: false,
              models: [
                {
                  name: '08A-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '12.700', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '7.85', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '3.96', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '7.95', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '12.07', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '31.00', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.50', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '14.38', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2820', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.19', 
                    },

                  ]
                },
                {
                  name: '10A-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.880', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '9.53', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.08', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '10.16', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '15.09', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '38.90', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '2.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '2.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '18.11', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4440', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.92', 
                    },

                  ]
                },
                {
                  name: '12A-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '12.70', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.94', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '11.91', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '48.50', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '2.40', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '2.40', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '22.78', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '6360', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.90', 
                    },

                  ]
                },
                {
                  name: '16A-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '61.80', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '29.29', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '11340', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '5.01', 
                    },

                  ]
                },
                {
                  name: '20A-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '19.00', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '76.00', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '35.76', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '17700', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '7.31', 
                    },

                  ]
                },
                {
                  name: '24A-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '11.10', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '22.23', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '95.40', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '45.44', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '25400', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '10.94', 
                    },

                  ]
                },
                {
                  name: '28A-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '12.70', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '42.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '102.90', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '5.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '5.60', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '48.87', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '34480', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '14.36', 
                    },

                  ]
                },
                {
                  name: '32A-2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '31.75', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '14.27', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '28.58', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '122.60', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '6.40', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '6.40', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '58.55', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '45360', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '19.10', 
                    },

                  ]
                },

                  
              ],
            },
            {
              id: 'cad-06',
              name: ['Type A3','Typ A3',],
              type: ['Roller Chain','Łańcuch Rolkowy',],
              img: ['cad-06-1',],
              clicked: false,
              models: [
                {
                  name: '08A-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '12.700', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '7.85', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '3.96', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '7.95', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '12.07', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '45.40', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.50', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '14.38', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4230', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.78', 
                    },

                  ]
                },
                {
                  name: '10A-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.880', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '9.53', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.08', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '10.16', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '15.09', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '57.00', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '2.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '2.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '18.11', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '6660', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.89', 
                    },

                  ]
                },
                {
                  name: '12A-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '12.70', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.94', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '11.91', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '71.30', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '2.40', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '2.40', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '22.78', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9540', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '4.26', 
                    },

                  ]
                },
                {
                  name: '16A-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '91.10', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '29.29', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '17010', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '7.47', 
                    },

                  ]
                },
                {
                  name: '20A-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '19.00', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '111.10', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '35.76', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '26550', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '11.01', 
                    },

                  ]
                },
                {
                  name: '24A-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '11.10', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '22.23', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '141.00', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '45.44', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '38100', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '16.50', 
                    },

                  ]
                },
                {
                  name: '28A-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '12.70', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '42.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '151.80', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '5.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '5.60', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '48.87', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '51720', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '21.70', 
                    },

                  ]
                },
                {
                  name: '32A-3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '31.75', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '14.27', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '28.58', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '181.90', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '6.40', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '6.40', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '58.55', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '68040', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '28.30', 
                    },

                  ]
                },

                  
              ],
            },

            {
              id: 'cad-07',
              name: ['B1-GL','B1-GL',],
              type: ['Roller Chain','Łańcuch Rolkowy',],
              img: ['cad-07-1',],
              clicked: false,
              models: [
                {
                  name: '10B1 GL', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '9.65', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.08', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '10.16', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '19.60', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2270', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.06', 
                    },

                  ]
                },
                {
                  name: '12B1 GL', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '11.68', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '12.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '16.13', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '22.70', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2950', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.32', 
                    },

                  ]
                },
                {
                  name: '16B1 GL', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '17.02', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '36.10', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5800', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '3.08', 
                    },

                  ]
                },
                {
                  name: '20B1 GL', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '19.56', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '10.19', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '26.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '41.60', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9500', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '4.16', 
                    },

                  ]
                },

                  
              ],
            },
            {
              id: 'cad-08',
              name: ['B2-GL','B2-GL',],
              type: ['Roller Chain','Łańcuch Rolkowy',],
              img: ['cad-08-1',],
              clicked: false,
              models: [
                {
                  name: '10B2 GL', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '9.65', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.08', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '10.16', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '36.20', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '16.59', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4540', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.00', 
                    },

                  ]
                },
                {
                  name: '12B2 GL', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '11.68', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '12.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '16.13', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '42.20', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '19.46', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5900', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.62', 
                    },

                  ]
                },
                {
                  name: '16B2 GL', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '17.02', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '68.00', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '31.88', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '11000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '6.10', 
                    },

                  ]
                },
                {
                  name: '20B2 GL', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '19.56', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '10.19', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '26.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '79.70', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '36.45', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '18000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '8.23', 
                    },

                  ]
                },

                  
              ],
            },
            {
              id: 'cad-09',
              name: ['B3-GL','B3-GL',],
              type: ['Roller Chain','Łańcuch Rolkowy',],
              img: ['cad-09-1',],
              clicked: false,
              models: [
                {
                  name: '10B3 GL', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '9.65', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.08', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '10.16', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '52.80', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '16.59', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '6810', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.87', 
                    },

                  ]
                },
                {
                  name: '12B3 GL', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '11.68', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '12.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '16.13', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '61.70', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '19.46', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8850', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '3.89', 
                    },

                  ]
                },
                {
                  name: '16B3 GL', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '17.02', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '99.90', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '31.88', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '16500', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '9.12', 
                    },

                  ]
                },
                {
                  name: '20B3 GL', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '19.56', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '10.19', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '26.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '116.10', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '36.45', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '27000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '11.34', 
                    },

                  ]
                },

                  
              ],
            },

            {
              id: 'cad-10',
              name: ['Type H','Typ H',],
              type: ['Roller Chain','Łańcuch Rolkowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: '60H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.500', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '12.70', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.94', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '11.91', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '18.10', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '29.10', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.87', 
                    },

                  ]
                },
                {
                  name: '80H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '36.60', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '7000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '3.10', 
                    },

                  ]
                },
                {
                  name: '100H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '17.05', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '43.60', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '10000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '4.52', 
                    },

                  ]
                },
                {
                  name: '120H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '11.10', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '22.23', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '36.10', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '63.90', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '5.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '5.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '12700', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '6.60', 
                    },

                  ]
                },
                {
                  name: '140H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '12.70', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '42.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '57.20', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '6.40', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '6.40', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '17240', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '8.30', 
                    },

                  ]
                },
                {
                  name: '160H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '31.75', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '14.27', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '28.58', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '67.20', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '7.20', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '7.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '22680', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '10.30', 
                    },

                  ]
                },

                  
              ],
            },

            {
              id: 'cad-11',
              name: ['Type H with Double Pitch','Typ H z Podwójną Podziałką',],
              type: ['Roller Chain','Łańcuch Rolkowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'C2060H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '12.70', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.94', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '11.91', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '17.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '29.10', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '3127', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.44', 
                    },

                  ]
                },
                {
                  name: 'C2062H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '12.70', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.38', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '22.20', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '17.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '3127', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.07', 
                    },

                  ]
                },
                {
                  name: 'C2080H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '23.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '39.20', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5560', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '3.12', 
                    },

                  ]
                },
                {
                  name: 'C2082H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '28.58', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '23.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '39.20', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5560', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '3.58', 
                    },

                  ]
                },
                {
                  name: 'C2100H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '63.500', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '28.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '43.60', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8687', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '3.56', 
                    },

                  ]
                },
                {
                  name: 'C2102H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '63.500', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '39.67', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '28.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '43.60', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8687', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '5.58', 
                    },

                  ]
                },
                {
                  name: 'C2120H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '76.200', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '11.10', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '22.23', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '34.40', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '53.90', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '5.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '5.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '12510', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '5.26', 
                    },

                  ]
                },
                {
                  name: 'C2122H', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '76.200', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '11.10', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '44.45', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '34.40', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '53.90', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '5.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '5.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '12510', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '8.26', 
                    },

                  ]
                },

                  
              ],
            },

            {
              id: 'cad-12',
              name: ['Type A&B with Double Pitch','Typ A&B z Podwójną Podziałką',],
              type: ['Roller Chain','Łańcuch Rolkowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: '210B', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '9.65', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.08', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '10.16', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.70', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '19.50', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2240', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '0.65', 
                    },

                  ]
                },
                {
                  name: '212B', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '11.68', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '12.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '22.50', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2900', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '0.76', 
                    },

                  ]
                },
                {
                  name: '216B', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '17.02', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '36.10', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '6000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.75', 
                    },

                  ]
                },
                {
                  name: '220B', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '63.500', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '19.56', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '10.19', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '26.40', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '41.60', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9500', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.62', 
                    },

                  ]
                },
                {
                  name: '224B', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '76.200', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '14.63', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '33.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '53.40', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '5.50', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '5.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '16000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '4.70', 
                    },

                  ]
                },

                {
                  name: '212A', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '12.57', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.94', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '11.91', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '25.90', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '2.40', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '2.40', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '3110', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.00', 
                    },

                  ]
                },
                {
                  name: '216A', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '15.75', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '32.70', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5560', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.55', 
                    },

                  ]
                },
                {
                  name: '220A', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '63.500', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '18.90', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.53', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '40.40', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8670', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.55', 
                    },

                  ]
                },
                {
                  name: '224A', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '76.200', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.22', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '11.10', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '22.23', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '35.70', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '50.30', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '12460', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '3.50', 
                    },

                  ]
                },

                  
              ],
            },
            
            
            // Conveyor Roller Chains
            {
              id: 'cad-13',
              name: ['Type A&B with Attachment','Typ A&B z Przyłączami',],
              type: ['Conveyor Roller Chain','Łańcuch Rolkowy Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: '10B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'h1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'm',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f/2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'k',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '12B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'h1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'm',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f/2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'k',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '16B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'h1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'm',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f/2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'k',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '20B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'h1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'm',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f/2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'k',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '24B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'h1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'm',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f/2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'k',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '28B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'h1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'm',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f/2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'k',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '32B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'h1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'm',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f/2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'k',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '12A1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'h1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'm',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f/2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'k',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '16A1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'h1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'm',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f/2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'k',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '20A1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'h1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'm',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f/2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'k',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '24A1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'h1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'm',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f/2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'k',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '28A1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'h1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'm',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f/2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'k',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '32A1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'h1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'm',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'f/2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'k',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                

              ]
            },
            {
              id: 'cad-14',
              name: ['Type A&B with Extended Pin','Typ A&B z Wydłużonym Sworzniem',],
              type: ['Conveyor Roller Chain','Łańcuch Rolkowy Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: '10B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '5.80', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '12B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '16B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '20B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '10.19', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '24B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '14.63', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '28B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '15.90', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '32B1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '17.81', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '12A1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '5.94', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '16A1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '20A1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '24A1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '11.10', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '28A1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '12.70', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                {
                  name: '32A1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '14.27', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'L',
                      unit: 'mm',
                      measures: "according to the customer's guidelines", 
                    },
                    

                  ]
                },
                

              ]
            },
            {
              id: 'cad-15',
              name: ['Accumulative STF','Akumulacyjny STF',],
              type: ['Conveyor Roller Chain','Łańcuch Rolkowy Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'STF-1055 S1', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '26.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '12.07', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '11.68', 
                    },
                    {
                      name: 'b5',
                      unit: 'mm',
                      measures: '11.10', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '31.50', 
                    },
                    
                    

                  ]
                },
                {
                  name: 'STF-1055 S2', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '26.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '12.07', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '11.68', 
                    },
                    {
                      name: 'b5',
                      unit: 'mm',
                      measures: '8.80', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '43.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '29.20', 
                    },
                    
                    

                  ]
                },
                {
                  name: 'STF-1055 S2a', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '12.07', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '11.68', 
                    },
                    {
                      name: 'b5',
                      unit: 'mm',
                      measures: '8.80', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '43.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '29.20', 
                    },
                    
                    

                  ]
                },
                {
                  name: 'STF-1055 S3', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '28.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '12.07', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '11.68', 
                    },
                    {
                      name: 'b5',
                      unit: 'mm',
                      measures: '7.50', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '27.00', 
                    },
                    
                    

                  ]
                },
                {
                  name: 'STF-1055 S5', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '38.50', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '17.02', 
                    },
                    {
                      name: 'b5',
                      unit: 'mm',
                      measures: '12.50', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '65.00', 
                    },
                    {
                      name: 'pt',
                      unit: 'mm',
                      measures: '44.90', 
                    },
                    
                    

                  ]
                },
                

              ]
            },

            {
              id: 'cad-16',
              name: ['Standard with Rubber Top','Standardowy z Gumową Nakładką',],
              type: ['Conveyor Roller Chain','Łańcuch Rolkowy Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: []
            },
            {
              id: 'cad-17',
              name: ['Standard with Sharp Top','Standardowy z Płytką Zębatą',],
              type: ['Conveyor Roller Chain','Łańcuch Rolkowy Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: []
            },
            

            // Leaf Chains
            {
              id: 'cad-18',
              name: ['Type LL','Typ LL',],
              type: ['Leaf Chain','Łańcuch Płytkowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'LL 1022', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x2', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '12.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '9.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2220', 
                    },

                  ]
                },
                {
                  name: 'LL 1023', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x3', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '12.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '12.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2840', 
                    },

                  ]
                },
                {
                  name: 'LL 1034', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '3x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '12.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '14.70', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '3900', 
                    },

                  ]
                },
                {
                  name: 'LL 1044', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '12.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '15.80', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4500', 
                    },

                  ]
                },
                {
                  name: 'LL 1046', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '12.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '19.50', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5650', 
                    },

                  ]
                },
                {
                  name: 'LL 1066', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '12.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '21.10', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '6810', 
                    },

                  ]
                },
                {
                  name: 'LL 1067', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x7', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '12.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '7350', 
                    },

                  ]
                },
                {
                  name: 'LL 1088', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '15.875', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.07', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '12.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '28.80', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.60', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9080', 
                    },

                  ]
                },

                {
                  name: 'LL 1222', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x2', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.90', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '10.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2950', 
                    },

                  ]
                },
                {
                  name: 'LL 1234', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '3x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.90', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '16.60', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5100', 
                    },

                  ]
                },
                {
                  name: 'LL 1244', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.90', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '17.90', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5900', 
                    },

                  ]
                },
                {
                  name: 'LL 1246', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.90', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '22.30', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '7300', 
                    },

                  ]
                },
                {
                  name: 'LL 1266', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.90', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '25.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8850', 
                    },

                  ]
                },
                {
                  name: 'LL 1267', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x7', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.90', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '27.30', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9500', 
                    },

                  ]
                },
                {
                  name: 'LL 1288', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.72', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '14.90', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '32.90', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '1.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '11800', 
                    },

                  ]
                },

                {
                  name: 'LL 1622', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x2', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '17.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5800', 
                    },

                  ]
                },
                {
                  name: 'LL 1623', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x3', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '20.50', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '7250', 
                    },

                  ]
                },
                {
                  name: 'LL 1634', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '3x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '26.80', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8700', 
                    },

                  ]
                },
                {
                  name: 'LL 1644', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '29.60', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '11600', 
                    },

                  ]
                },
                {
                  name: 'LL 1646', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '35.30', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '14400', 
                    },

                  ]
                },
                {
                  name: 'LL 1656', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '5x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '39.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '15950', 
                    },

                  ]
                },
                {
                  name: 'LL 1666', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '42.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '20000', 
                    },

                  ]
                },
                {
                  name: 'LL 1688', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '55.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '23000', 
                    },

                  ]
                },

                {
                  name: 'LL 2022', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x2', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '10.18', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.30', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '20.10', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9500', 
                    },

                  ]
                },
                {
                  name: 'LL 2034', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '3x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '10.18', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.30', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '30.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '16600', 
                    },

                  ]
                },
                {
                  name: 'LL 2044', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '10.18', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.30', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '33.80', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '19000', 
                    },

                  ]
                },
                {
                  name: 'LL 2066', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '10.18', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.30', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '50.10', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '28000', 
                    },

                  ]
                },
                {
                  name: 'LL 2067', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x7', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '10.18', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.30', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '52.50', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '30800', 
                    },

                  ]
                },
                {
                  name: 'LL 2088', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '10.18', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.30', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '65.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '38000', 
                    },

                  ]
                },

                {
                  name: 'LL 2422', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x2', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '14.63', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '32.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '28.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9790', 
                    },

                  ]
                },
                {
                  name: 'LL 2434', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '3x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '14.63', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '32.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '41.50', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '29750', 
                    },

                  ]
                },
                {
                  name: 'LL 2444', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '14.63', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '32.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '46.30', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '19570', 
                    },

                  ]
                },
                {
                  name: 'LL 2466', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '14.63', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '32.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '66.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '29360', 
                    },

                  ]
                },
                {
                  name: 'LL 2488', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '14.63', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '32.20', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '86.60', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '68000', 
                    },

                  ]
                },

                {
                  name: 'LL 2822', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x2', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '15.90', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '37.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '32.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '12900', 
                    },

                  ]
                },
                {
                  name: 'LL 2844', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '15.90', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '37.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '56.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '25800', 
                    },

                  ]
                },
                {
                  name: 'LL 2866', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '15.90', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '37.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '80.60', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '60000', 
                    },

                  ]
                },
                {
                  name: 'LL 2888', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '15.90', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '37.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '105.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '80000', 
                    },

                  ]
                },

                {
                  name: 'LL 3222', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x2', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '17.81', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '41.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '33.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '16900', 
                    },

                  ]
                },
                {
                  name: 'LL 3223', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x3', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '17.81', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '41.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '39.80', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '26000', 
                    },

                  ]
                },
                {
                  name: 'LL 3234', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '3x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '17.81', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '41.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '52.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '42000', 
                    },

                  ]
                },
                {
                  name: 'LL 3244', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '17.81', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '41.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '57.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '33810', 
                    },

                  ]
                },
                {
                  name: 'LL 3266', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '17.81', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '41.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '81.60', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '50720', 
                    },

                  ]
                },
                {
                  name: 'LL 3288', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '17.81', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '41.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '105.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '104000', 
                    },

                  ]
                },




              ]
            },

            {
              id: 'cad-19',
              name: ['Type AL','Typ AL',],
              type: ['Leaf Chain','Łańcuch Płytkowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'AL622', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x2', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '15.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '12.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '2.40', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '3700', 
                    },

                  ]
                },
                {
                  name: 'AL644', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '15.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '22.70', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '2.40', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '6400', 
                    },

                  ]
                },
                {
                  name: 'AL666', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '15.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '32.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '2.40', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '10100', 
                    },

                  ]
                },
                {
                  name: 'AL688', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '5.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '15.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '42.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '2.40', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '13340', 
                    },

                  ]
                },

                {
                  name: 'AL822', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x2', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '20.50', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5670', 
                    },

                  ]
                },
                {
                  name: 'AL844', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '20.50', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '29.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '11340', 
                    },

                  ]
                },
                {
                  name: 'AL866', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '20.50', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '42.50', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '17000', 
                    },

                  ]
                },
                {
                  name: 'AL888', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '20.50', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '55.70', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '22000', 
                    },

                  ]
                },

                {
                  name: 'AL1022', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x2', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.53', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '25.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '19.60', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8850', 
                    },

                  ]
                },
                {
                  name: 'AL1044', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.53', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '25.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '35.90', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '17700', 
                    },

                  ]
                },
                {
                  name: 'AL1066', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.53', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '25.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '52.30', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '26500', 
                    },

                  ]
                },
                {
                  name: 'AL1088', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '31.750', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.53', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '25.60', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '68.50', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '35400', 
                    },

                  ]
                },

                {
                  name: 'AL1222', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x2', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '11.10', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '30.50', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '24.30', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '12700', 
                    },

                  ]
                },
                {
                  name: 'AL1244', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '11.10', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '30.50', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '43.80', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '25400', 
                    },

                  ]
                },
                {
                  name: 'AL1266', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '11.10', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '30.50', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '63.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '38100', 
                    },

                  ]
                },
                {
                  name: 'AL1288', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '38.100', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '11.10', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '30.50', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '82.60', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.80', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '50800', 
                    },

                  ]
                },

                {
                  name: 'AL1444', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '12.64', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '36.40', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '51.30', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '37270', 
                    },

                  ]
                },
                {
                  name: 'AL1466', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '12.64', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '36.40', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '74.56', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '55900', 
                    },

                  ]
                },
                {
                  name: 'AL1488', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '44.450', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '12.64', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '36.40', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '97.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '74500', 
                    },

                  ]
                },




              ]
            },

            {
              id: 'cad-20',
              name: ['Type LH/BL','Typ LH/BL',],
              type: ['Leaf Chain','Łańcuch Płytkowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'LH1222', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL622', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x2', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '18.10', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '16.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4893', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.45', 
                    },

                  ]
                },
                {
                  name: 'LH1223', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL623', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x3', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '18.10', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '19.80', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4893', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '1.80', 
                    },

                  ]
                },
                {
                  name: 'LH1234', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL634', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '3x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '18.10', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '26.40', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '7570', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.50', 
                    },

                  ]
                },
                {
                  name: 'LH1244', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL644', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '18.10', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '29.80', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9786', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.90', 
                    },

                  ]
                },
                {
                  name: 'LH1246', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL646', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '18.10', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '36.50', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9786', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '3.60', 
                    },

                  ]
                },
                {
                  name: 'LH1266', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL666', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '18.10', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '43.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '14679', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '4.30', 
                    },

                  ]
                },
                {
                  name: 'LH1288', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL688', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '19.050', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '7.92', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '18.10', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '57.60', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.20', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '19500', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '5.80', 
                    },

                  ]
                },

                {
                  name: 'LH1622', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL822', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x2', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '21.30', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8450', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.20', 
                    },

                  ]
                },
                {
                  name: 'LH1623', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL823', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '2x3', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '24.50', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8450', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '2.70', 
                    },

                  ]
                },
                {
                  name: 'LH1634', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL834', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '3x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '33.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '12670', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '3.80', 
                    },

                  ]
                },
                {
                  name: 'LH1644', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL844', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x4', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '36.90', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '16900', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '4.30', 
                    },

                  ]
                },
                {
                  name: 'LH1646', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL846', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '4x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '46.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '17000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '5.40', 
                    },

                  ]
                },
                {
                  name: 'LH1666', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL866', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '6x6', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '53.60', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '20000', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '6.50', 
                    },

                  ]
                },
                {
                  name: 'LH1688', 
                  specs: [
                    {
                      name: 'ANSI',
                      unit: null,
                      measures: 'BL888', 
                    },
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'layout',
                      unit: 'mm',
                      measures: '8x8', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '9.52', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '71.50', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '43270', 
                    },
                    {
                      name: 'q',
                      unit: 'kg/m',
                      measures: '8.60', 
                    },

                  ]
                },




              ]
            },

 
            // Agricultural Chains
            {
              id: 'cad-21',
              name: ['Type BX','Typ BX',],
              type: ['Agricultural Chain','Łańcuch Rolniczy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: '16BX', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '25.400', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '12.70', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '33.10', 
                    },
                    {
                      name: 's1',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 's2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '6500', 
                    },
                    

                  ]
                },

                  
              ],
            },

            {
              id: 'cad-22',
              name: ['Type S55X','Typ S55X',],
              type: ['Agricultural Chain','Łańcuch Rolniczy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'S55X', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '41.300', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'd1 max',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'd3 max',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'g max',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'L1 max',
                      unit: 'mm',
                      measures: '36.80', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5000', 
                    },
                    

                  ]
                },

                  
              ],
            },

            {
              id: 'cad-23',
              name: ['Type S55X with Attachment','Typ S55X z Przyłączem',],
              type: ['Agricultural Chain','Łańcuch Rolniczy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'S55X', 
                  specs: [
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '17.00', 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: '52.00', 
                    },
                    

                  ]
                },

                  
              ],
            },
            {
              id: 'cad-24',
              name: ['Type S55X with Strenghtened Attachment','Typ S55X z Przyłączem Wzmocnionym',],
              type: ['Agricultural Chain','Łańcuch Rolniczy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'S55X', 
                  specs: [
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '17.00', 
                    },
                    {
                      name: 'f',
                      unit: 'mm',
                      measures: '52.00', 
                    },
                    

                  ]
                },

                  
              ],
            },
            {
              id: 'cad-25',
              name: ['Type S55X for Harvesting Machines','Typ S55X do Zbiorów',],
              type: ['Agricultural Chain','Łańcuch Rolniczy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'Small', 
                  specs: [
                    {
                      name: 'dimentions',
                      unit: 'mm',
                      measures: '110.00', 
                    },
                                       

                  ]
                },
                {
                  name: 'Middle', 
                  specs: [
                    {
                      name: 'dimentions',
                      unit: 'mm',
                      measures: '136.00', 
                    },
                                       

                  ]
                },
                {
                  name: 'Big', 
                  specs: [
                    {
                      name: 'dimentions',
                      unit: 'mm',
                      measures: '166.00', 
                    },
                                       

                  ]
                },

                  
              ],
            },
            {
              id: 'cad-26',
              name: ['Type S55X with Angle Bar','Typ S55X z Listwą',],
              type: ['Agricultural Chain','Łańcuch Rolniczy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'S55X-40', 
                  specs: [
                    {
                      name: 'bar quantity',
                      unit: 'pcs',
                      measures: '40', 
                    },
                                       

                  ]
                },
                {
                  name: 'S55X-42', 
                  specs: [
                    {
                      name: 'bar quantity',
                      unit: 'pcs',
                      measures: '42', 
                    },
                                       

                  ]
                },
                

                  
              ],
            },

            {
              id: 'cad-27',
              name: ['Corn Harvesting Type','Typu do Zbioru Kukurydzy',],
              type: ['Agricultural Chain','Łańcuch Rolniczy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: '5930', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '30.000', 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: 'n/a', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: 'n/a', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: 'n/a', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '20.70', 
                    },
                    {
                      name: 'g2',
                      unit: 'mm',
                      measures: 'n/a', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '74.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '35.30', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '3200', 
                    },
                    

                  ]
                },
                {
                  name: 'E-63581.05.00', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '41.300', 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: 'n/a', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '8.28', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: 'n/a', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '15.88', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: 'n/a', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'g2',
                      unit: 'mm',
                      measures: 'n/a', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '92.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '35.30', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5000', 
                    },
                    

                  ]
                },
                {
                  name: 'CA650', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: '50.800', 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: 'n/a', 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '10.19', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '65.30', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '19.05', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '25.50', 
                    },
                    {
                      name: 'g2',
                      unit: 'mm',
                      measures: '63.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '40.60', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: 'n/a', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9500', 
                    },
                    

                  ]
                },

                  
              ],
            },

            // Conveyor Chains
            {
              id: 'cad-28',
              name: ['Type FV','Typ FV',],
              type: ['Conveyor Chain','Łańcuch Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'FV40', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['40','50','63','80','100','125','160','-'], 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'b3',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '10.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '15.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '32.00', 
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '37.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '26.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4000', 
                    },
                    

                  ]
                },
                {
                  name: 'FV63', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['50','63','80','100','125','160','200','-'], 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: '22.00', 
                    },
                    {
                      name: 'b3',
                      unit: 'mm',
                      measures: '31.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '12.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '26.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '46.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '6300', 
                    },
                    

                  ]
                },
                {
                  name: 'FV90', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['50','63','80','100','125','160','200','250'], 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 'b3',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '14.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '63.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '73.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '53.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9000', 
                    },
                    

                  ]
                },
                {
                  name: 'FV112', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['80','100','125','160','200','250','315','-',], 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'b3',
                      unit: 'mm',
                      measures: '43.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '22.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '32.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '55.00', 
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '72.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '87.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '63.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '11200', 
                    },
                    

                  ]
                },
                {
                  name: 'FV140', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['80','100','125','160','200','250','315','400',], 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 'b3',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '26.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '80.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '95.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '68.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '14000', 
                    },
                    

                  ]
                },
                {
                  name: 'FV180', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['100','125','160','200','250','315','400','500',], 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 'b3',
                      unit: 'mm',
                      measures: '62.50', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '42.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '100.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '120.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '86.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '8.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '18000', 
                    },
                    

                  ]
                },
                

                  
              ],
            },
            {
              id: 'cad-29',
              name: ['Type FVT','Typ FVT',],
              type: ['Conveyor Chain','Łańcuch Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'FVT40', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['40','50','63','80','100','125','160','-'], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '10.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '15.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '32.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '22.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '37.00', 
                    },
                    {
                      name: 'a2',
                      unit: 'mm',
                      measures: '40.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4000', 
                    },
                    

                  ]
                },
                {
                  name: 'FVT63', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['50','63','80','100','125','160','200','-'], 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: '22.00', 
                    },
                    {
                      name: 'b3',
                      unit: 'mm',
                      measures: '31.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '12.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '46.00', 
                    },
                    {
                      name: 'a2',
                      unit: 'mm',
                      measures: '50.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '6300', 
                    },
                    

                  ]
                },
                {
                  name: 'FVT90', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['50','63','80','100','125','160','200','250'], 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 'b3',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '14.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '27.50', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '53.00', 
                    },
                    {
                      name: 'a2',
                      unit: 'mm',
                      measures: '57.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9000', 
                    },
                    

                  ]
                },
                {
                  name: 'FVT112', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['80','100','125','160','200','250','315','-',], 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'b3',
                      unit: 'mm',
                      measures: '43.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '22.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '55.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '63.00', 
                    },
                    {
                      name: 'a2',
                      unit: 'mm',
                      measures: '67.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '11200', 
                    },
                    

                  ]
                },
                {
                  name: 'FVT140', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['80','100','125','160','200','250','315','400',], 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 'b3',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '26.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '37.50', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '68.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '74.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '14000', 
                    },
                    

                  ]
                },
                {
                  name: 'FVT180', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['100','125','160','200','250','315','400','500',], 
                    },
                    {
                      name: 'b1',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 'b3',
                      unit: 'mm',
                      measures: '62.50', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '8.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '86.00', 
                    },
                    {
                      name: 'a2',
                      unit: 'mm',
                      measures: '93.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '18000', 
                    },
                    

                  ]
                },
                

                  
              ],
            },
            {
              id: 'cad-30',
              name: ['Type FVC','Typ FVC',],
              type: ['Conveyor Chain','Łańcuch Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'FVC63', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['63','80','100','125','160','-','-',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '22.00', 
                    },
                    {
                      name: 'd1 min',
                      unit: 'mm',
                      measures: '8.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '12.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '26.00', 
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'd7',
                      unit: 'mm',
                      measures: '63.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4600', 
                    },
                    

                  ]
                },
                {
                  name: 'FVC90', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['63','80','100','125','160','200','250',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 'd1 min',
                      unit: 'mm',
                      measures: '10.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '14.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '63.00', 
                    },
                    {
                      name: 'd7',
                      unit: 'mm',
                      measures: '78.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '53.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '7300', 
                    },
                    

                  ]
                },
                {
                  name: 'FVC112', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['100','125','160','200','250','-','-',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'd1 min',
                      unit: 'mm',
                      measures: '11.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '22.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '32.00', 
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '55.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '72.00', 
                    },
                    {
                      name: 'd7',
                      unit: 'mm',
                      measures: '90.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '62.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '9000', 
                    },
                    

                  ]
                },
                {
                  name: 'FVC140', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['100','125','160','200','250','-','-',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 'd1 min',
                      unit: 'mm',
                      measures: '12.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '26.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '80.00', 
                    },
                    {
                      name: 'd7',
                      unit: 'mm',
                      measures: '100.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '67.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '11000', 
                    },
                    

                  ]
                },
                {
                  name: 'FVC180', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['125','160','200','250','315','-','-',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 'd1 min',
                      unit: 'mm',
                      measures: '14.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '42.00', 
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '100.00', 
                    },
                    {
                      name: 'd7',
                      unit: 'mm',
                      measures: '125.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '86.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '8.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '14500', 
                    },
                    

                  ]
                },
                {
                  name: 'FVC250', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['160','200','250','315','400','-','-',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '55.00', 
                    },
                    {
                      name: 'd1 min',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '26.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '80.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '125.00', 
                    },
                    {
                      name: 'd7',
                      unit: 'mm',
                      measures: '155.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '97.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '8.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '21500', 
                    },
                    

                  ]
                },
                {
                  name: 'FVC315', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['160','200','250','315','400','-','-',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '65.00', 
                    },
                    {
                      name: 'd1 min',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '42.00', 
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '90.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '140.00', 
                    },
                    {
                      name: 'd7',
                      unit: 'mm',
                      measures: '175.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '117.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '10.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '29500', 
                    },
                    

                  ]
                },
                
                

                  
              ],
            },
            {
              id: 'cad-31',
              name: ['Type FVR','Typ FVR',],
              type: ['Conveyor Chain','Łańcuch Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'FVR40', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['40','63','100','-','-','-',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '10.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '15.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '43.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '26.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4200', 
                    },
                    

                  ]
                },
                {
                  name: 'FVR63', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['63','100','125','160','-','-',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '22.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '12.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '54.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '6400', 
                    },
                    

                  ]
                },
                {
                  name: 'FVR90', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['63','100','125','160','200','250',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '14.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '61.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '10000', 
                    },
                    

                  ]
                },
                {
                  name: 'FVR112', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['100','125','160','200','250','-',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '22.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '12000', 
                    },
                    

                  ]
                },
                {
                  name: 'FVR140', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['100','125','160','200','250','315',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '26.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '79.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '14500', 
                    },
                    

                  ]
                },
                {
                  name: 'FVR180', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['125','160','200','250','315','400',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '99.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '8.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '19000', 
                    },
                    

                  ]
                },
                {
                  name: 'FVR250', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['125','160','200','250','315','400',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '55.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '26.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '113.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '8.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '27500', 
                    },
                    

                  ]
                },
                {
                  name: 'FVR315', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['160','200','250','315','400','-',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '65.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '42.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '130.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '10.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '37000', 
                    },
                    

                  ]
                },
                
                

                  
              ],
            },
            {
              id: 'cad-32',
              name: ['Type FV with Angle Attachment','Typ FV z Przyłączem Kątowym',],
              type: ['Conveyor Chain','Łańcuch Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'FV40', 
                  specs: [
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '31.00', 
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '6.60', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'f2 max',
                      unit: 'mm',
                      measures: '100.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '25x25x3', 
                    },
                    

                  ]
                },
                {
                  name: 'FV63', 
                  specs: [
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '9.00', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '68.00', 
                    },
                    {
                      name: 'f2 max',
                      unit: 'mm',
                      measures: '110.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '30x30x3', 
                    },
                    

                  ]
                },
                {
                  name: 'FV90', 
                  specs: [
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '80.00', 
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '85.00', 
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '9.00', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '80.00', 
                    },
                    {
                      name: 'f2 max',
                      unit: 'mm',
                      measures: '130.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '40x40x4', 
                    },
                    

                  ]
                },
                {
                  name: 'FV112', 
                  specs: [
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '65.00', 
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '75.00', 
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '90.00', 
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '105.00', 
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '11.00', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '100.00', 
                    },
                    {
                      name: 'f2 max',
                      unit: 'mm',
                      measures: '140.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '40x40x5', 
                    },
                    

                  ]
                },
                {
                  name: 'FV140', 
                  specs: [
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '55.00', 
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '65.00', 
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '75.00', 
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '90.00', 
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '105.00', 
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '125.00', 
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '11.00', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '100.00', 
                    },
                    {
                      name: 'f2 max',
                      unit: 'mm',
                      measures: '170.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '50x50x5', 
                    },
                    

                  ]
                },
                {
                  name: 'FV180', 
                  specs: [
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '65.00', 
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '80.00', 
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '95.00', 
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '110.00', 
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '130.00', 
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '130.00', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '13.50', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '128.00', 
                    },
                    {
                      name: 'f2 max',
                      unit: 'mm',
                      measures: '190.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '50x50x5', 
                    },
                    

                  ]
                },
                  
              ],
            },


            {
              id: 'cad-33',
              name: ['Type M','Typ M',],
              type: ['Conveyor Chain','Łańcuch Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'M20', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['40','50','63','80','100','125','160',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '22.20',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '9.00',  
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '12.50',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '25.00',  
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '25.00',  
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '33.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2000', 
                    },
                    

                  ]
                },
                {
                  name: 'M28', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['50','63','80','100','125','160','200',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '25.20',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '7.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '10.00',  
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '15.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '30.00',  
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '30.00',  
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2800', 
                    },
                    

                  ]
                },
                {
                  name: 'M40', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['63','80','100','125','160','200','250',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '28.30',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '8.50', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '12.50',  
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '18.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '36.00',  
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '36.00',  
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '42.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '41.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4000', 
                    },
                    

                  ]
                },
                {
                  name: 'M56', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['63','80','100','125','160','200','250',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '33.30',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '10.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '15.00',  
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '21.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '42.00',  
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '42.00',  
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5600', 
                    },
                    

                  ]
                },
                {
                  name: 'M80', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['80','100','125','160','200','250','315',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '28.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '39.40',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '12.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '18.00',  
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '25.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '50.00',  
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '50.00',  
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '55.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8000', 
                    },
                    

                  ]
                },
                {
                  name: 'M112', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['80','100','125','160','200','250','315',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '32.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '45.50',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '15.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '21.00',  
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '30.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '60.00',  
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '60.00',  
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '65.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '11200', 
                    },
                    

                  ]
                },
                {
                  name: 'M160', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['100','125','160','200','250','315','400',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '37.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '52.50',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '25.00',  
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '36.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '70.00',  
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '70.00',  
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '85.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '75.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '7.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '16000', 
                    },
                    

                  ]
                },
                {
                  name: 'M224', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['125','160','200','250','315','400','500',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '43.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '60.60',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '30.00',  
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '42.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '85.00',  
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '85.00',  
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '100.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '85.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '8.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '22400', 
                    },
                    

                  ]
                },
                {
                  name: 'M315', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['160','200','250','315','400','500','630',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '70.70',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '36.00',  
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '50.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '100.00',  
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '100.00',  
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '120.00', 
                    },
                    {
                      name: 'a1',
                      unit: 'mm',
                      measures: '100.00', 
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '10.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '31500', 
                    },
                    

                  ]
                },
                  
              ],
            },
            {
              id: 'cad-34',
              name: ['Type MT','Typ MT',],
              type: ['Conveyor Chain','Łańcuch Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'MT20', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['40','50','63','80','100','125','160',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '22.20',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '9.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '25.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '33.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2000', 
                    },
                    

                  ]
                },
                {
                  name: 'MT28', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['50','63','80','100','125','160','200',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '25.20',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '7.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '10.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '30.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2800', 
                    },
                    

                  ]
                },
                {
                  name: 'MT40', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['63','80','100','125','160','200','250',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '28.30',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '8.50', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '12.50',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '36.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '22.50', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '41.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4000', 
                    },
                    

                  ]
                },
                {
                  name: 'MT56', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['63','80','100','125','160','200','250',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '33.30',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '10.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '15.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '42.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5600', 
                    },
                    

                  ]
                },
                {
                  name: 'MT80', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['80','100','125','160','200','250','315',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '28.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '39.40',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '12.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '18.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '50.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '32.50', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '55.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8000', 
                    },
                    

                  ]
                },
                {
                  name: 'MT112', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['80','100','125','160','200','250','315',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '32.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '45.50',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '15.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '21.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '60.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '65.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '11200', 
                    },
                    

                  ]
                },
                {
                  name: 'MT160', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['100','125','160','200','250','315','400',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '37.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '52.50',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '25.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '70.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '75.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '7.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '16000', 
                    },
                    

                  ]
                },
                {
                  name: 'MT224', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['125','160','200','250','315','400','500',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '43.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '60.60',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '30.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '85.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '90.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '85.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '8.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '22400', 
                    },
                    

                  ]
                },
                {
                  name: 'MT315', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['160','200','250','315','400','500','630',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '48.00', 
                    },
                    {
                      name: 'b3 min',
                      unit: 'mm',
                      measures: '70.70',  
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '36.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '100.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '100.00', 
                    },
                    {
                      name: 'g1',
                      unit: 'mm',
                      measures: '65.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '100.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '10.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '31500', 
                    },
                    

                  ]
                },
                  
              ],
            },

            {
              id: 'cad-35',
              name: ['Type MC','Typ MC',],
              type: ['Conveyor Chain','Łańcuch Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'MC28', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['63','80','100','125','160',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'd1 min',
                      unit: 'mm',
                      measures: '8.20',  
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '13.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '17.50',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '25.00',  
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '36.00',  
                    },
                    {
                      name: 'd7',
                      unit: 'mm',
                      measures: '45.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '36.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '28000', 
                    },
                    

                  ]
                },
                {
                  name: 'MC56', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['80','100','125','160','200',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'd1 min',
                      unit: 'mm',
                      measures: '10.20',  
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '15.50', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '21.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '30.00',  
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '50.00',  
                    },
                    {
                      name: 'd7',
                      unit: 'mm',
                      measures: '60.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '56000', 
                    },
                    

                  ]
                },
                {
                  name: 'MC112', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['100','125','160','200','250',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '32.00', 
                    },
                    {
                      name: 'd1 min',
                      unit: 'mm',
                      measures: '14.30',  
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '22.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '29.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '42.00',  
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '70.00',  
                    },
                    {
                      name: 'd7',
                      unit: 'mm',
                      measures: '85.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '62.50', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '112000', 
                    },
                    

                  ]
                },
                {
                  name: 'MC224', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['125','160','200','250','315',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '43.00', 
                    },
                    {
                      name: 'd1 min',
                      unit: 'mm',
                      measures: '20.30',  
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '31.00', 
                    },
                    {
                      name: 'd3',
                      unit: 'mm',
                      measures: '41.00',  
                    },
                    {
                      name: 'd4',
                      unit: 'mm',
                      measures: '60.00',  
                    },
                    {
                      name: 'd5',
                      unit: 'mm',
                      measures: '100.00', 
                    },
                    {
                      name: 'd6',
                      unit: 'mm',
                      measures: '100.00',  
                    },
                    {
                      name: 'd7',
                      unit: 'mm',
                      measures: '120.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '83.00', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '8.00', 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '224000', 
                    },
                    

                  ]
                },
                  
              ],
            },

            {
              id: 'cad-36',
              name: ['Type MR','Typ MR',],
              type: ['Conveyor Chain','Łańcuch Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'MR20', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['40','50','63','80','100','125','160',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '9.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '36.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '2.50', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2000', 
                    },
                    

                  ]
                },
                {
                  name: 'MR28', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['50','63','80','100','125','160','200',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '7.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '10.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '42.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '2800', 
                    },
                    

                  ]
                },
                {
                  name: 'MR40', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['63','80','100','125','160','200','250',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '8.50', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '12.50',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '47.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '3.50', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '4000', 
                    },
                    

                  ]
                },
                {
                  name: 'MR56', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['63','80','100','125','160','200','250',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '24.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '10.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '15.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '56.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '4.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '5600', 
                    },
                    

                  ]
                },
                {
                  name: 'MR80', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['80','100','125','160','200','250','315',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '28.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '12.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '18.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '64.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '5.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '8000', 
                    },
                    

                  ]
                },
                {
                  name: 'MR112', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['80','100','125','160','200','250','315',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '32.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '15.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '21.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '75.20', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '6.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '11200', 
                    },
                    

                  ]
                },
                {
                  name: 'MR160', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['100','125','160','200','250','315','400',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '37.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '25.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '50.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '88.30', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '7.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '16000', 
                    },
                    

                  ]
                },
                {
                  name: 'MR224', 
                  specs: [
                    {
                      name: 'P',
                      unit: 'mm',
                      measures: ['125','160','200','250','315','400','500',], 
                    },
                    {
                      name: 'b1 min',
                      unit: 'mm',
                      measures: '43.00', 
                    },
                    {
                      name: 'd1',
                      unit: 'mm',
                      measures: '21.00', 
                    },
                    {
                      name: 'd2',
                      unit: 'mm',
                      measures: '30.00',  
                    },
                    {
                      name: 'g',
                      unit: 'mm',
                      measures: '60.00', 
                    },
                    {
                      name: 'L1',
                      unit: 'mm',
                      measures: '99.30', 
                    },
                    {
                      name: 's1=s2',
                      unit: 'mm',
                      measures: '8.00', 
                    },
                    {
                      name: 'W',
                      unit: null,
                      measures: "according to the customer's guidelines", 
                    },
                    {
                      name: 'FB min',
                      unit: 'da[N]',
                      measures: '22400', 
                    },
                    

                  ]
                },
                  
              ],
            },

            {
              id: 'cad-37',
              name: ['Type M with Angle Attachment','Typ FV z Przyłączem Kątowym',],
              type: ['Conveyor Chain','Łańcuch Transportowy',],
              img: ['cad-xxx',],
              clicked: false,
              models: [
                {
                  name: 'M20', 
                  specs: [
                    {
                      name: 'L2 [P=40mm]',
                      unit: 'mm',
                      measures: '14.00', 
                    },
                    {
                      name: 'L2 [P=50mm]',
                      unit: 'mm',
                      measures: '14.00', 
                    },
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 'L2 [P=80mm]',
                      unit: 'mm',
                      measures: '50.00',  
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '65.00',  
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '65.00',  
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=500mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=630mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '6.60', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '54.00', 
                    },
                    {
                      name: 'f2',
                      unit: 'mm',
                      measures: '84.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '16.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '25x25x3', 
                    },
                    

                  ]
                },
                {
                  name: 'M28', 
                  specs: [
                    {
                      name: 'L2 [P=40mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=50mm]',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'L2 [P=80mm]',
                      unit: 'mm',
                      measures: '45.00',  
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '60.00',  
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '85.00',  
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '85.00',
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '85.00',
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=500mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=630mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '9.00', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '64.00', 
                    },
                    {
                      name: 'f2',
                      unit: 'mm',
                      measures: '100.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '30x30x3', 
                    },
                    

                  ]
                },
                {
                  name: 'M40', 
                  specs: [
                    {
                      name: 'L2 [P=40mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=50mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '20.00', 
                    },
                    {
                      name: 'L2 [P=80mm]',
                      unit: 'mm',
                      measures: '40.00',  
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '60.00',  
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '85.00',  
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '85.00',
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '85.00',
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '85.00',
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=500mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=630mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '9.00', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '70.00', 
                    },
                    {
                      name: 'f2',
                      unit: 'mm',
                      measures: '112.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '25.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '30x30x3', 
                    },
                    

                  ]
                },
                {
                  name: 'M56', 
                  specs: [
                    {
                      name: 'L2 [P=40mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=50mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '22.00', 
                    },
                    {
                      name: 'L2 [P=80mm]',
                      unit: 'mm',
                      measures: '22.00',  
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '50.00',  
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '75.00',  
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '110.00',
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '110.00',
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '110.00',
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=500mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=630mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '11.00', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '88.00', 
                    },
                    {
                      name: 'f2',
                      unit: 'mm',
                      measures: '140.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '30.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '40x40x4', 
                    },
                    

                  ]
                },
                {
                  name: 'M80', 
                  specs: [
                    {
                      name: 'L2 [P=40mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=50mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=80mm]',
                      unit: 'mm',
                      measures: '22.00',  
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '22.00',  
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '75.00',  
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '110.00',
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '150.00',
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '150.00',
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '150.00',
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=500mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=630mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '11.00', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '96.00', 
                    },
                    {
                      name: 'f2',
                      unit: 'mm',
                      measures: '160.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '35.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '40x40x4', 
                    },
                    

                  ]
                },
                {
                  name: 'M112', 
                  specs: [
                    {
                      name: 'L2 [P=40mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=50mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=80mm]',
                      unit: 'mm',
                      measures: '28.00',  
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '28.00',  
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '65.00',  
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '95.00',
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '130.00',
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '130.00',
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '130.00',
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=500mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=630mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '14.00', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '110.00', 
                    },
                    {
                      name: 'f2',
                      unit: 'mm',
                      measures: '184.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '40.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '50x50x6', 
                    },
                    

                  ]
                },
                {
                  name: 'M160', 
                  specs: [
                    {
                      name: 'L2 [P=40mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=50mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=80mm]',
                      unit: 'mm',
                      measures: '-',  
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '30.00',  
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '30.00',  
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '80.00',
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '115.00',
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '175.00',
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '175.00',
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '175.00',
                    },
                    {
                      name: 'L2 [P=500mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=630mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '14.00', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '124.00', 
                    },
                    {
                      name: 'f2',
                      unit: 'mm',
                      measures: '200.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '45.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '50x50x6', 
                    },
                    

                  ]
                },
                {
                  name: 'M224', 
                  specs: [
                    {
                      name: 'L2 [P=40mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=50mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=80mm]',
                      unit: 'mm',
                      measures: '-',  
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '-',  
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '35.00',  
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '35.00',
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '100.00',
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '160.00',
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '225.00',
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '225.00',
                    },
                    {
                      name: 'L2 [P=500mm]',
                      unit: 'mm',
                      measures: '225.00',
                    },
                    {
                      name: 'L2 [P=630mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '140.00', 
                    },
                    {
                      name: 'f2',
                      unit: 'mm',
                      measures: '228.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '55.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '60x60x8', 
                    },
                    

                  ]
                },
                {
                  name: 'M315', 
                  specs: [
                    {
                      name: 'L2 [P=40mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=50mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=63mm]',
                      unit: 'mm',
                      measures: '-', 
                    },
                    {
                      name: 'L2 [P=80mm]',
                      unit: 'mm',
                      measures: '-',  
                    },
                    {
                      name: 'L2 [P=100mm]',
                      unit: 'mm',
                      measures: '-',  
                    },
                    {
                      name: 'L2 [P=125mm]',
                      unit: 'mm',
                      measures: '-',  
                    },
                    {
                      name: 'L2 [P=160mm]',
                      unit: 'mm',
                      measures: '35.00',
                    },
                    {
                      name: 'L2 [P=200mm]',
                      unit: 'mm',
                      measures: '85.00',
                    },
                    {
                      name: 'L2 [P=250mm]',
                      unit: 'mm',
                      measures: '135.00',
                    },
                    {
                      name: 'L2 [P=315mm]',
                      unit: 'mm',
                      measures: '190.00',
                    },
                    {
                      name: 'L2 [P=400mm]',
                      unit: 'mm',
                      measures: '190.00',
                    },
                    {
                      name: 'L2 [P=500mm]',
                      unit: 'mm',
                      measures: '190.00',
                    },
                    {
                      name: 'L2 [P=630mm]',
                      unit: 'mm',
                      measures: '190.00',
                    },
                    {
                      name: 'd',
                      unit: 'mm',
                      measures: '18.00', 
                    },
                    {
                      name: 'f1',
                      unit: 'mm',
                      measures: '160.00', 
                    },
                    {
                      name: 'f2',
                      unit: 'mm',
                      measures: '250.00', 
                    },
                    {
                      name: 'h',
                      unit: 'mm',
                      measures: '65.00', 
                    },
                    {
                      name: 'angle',
                      unit: 'mm',
                      measures: '70x70x8', 
                    },
                    

                  ]
                },
                
                  
              ],
            },






            // Special Chains
            {
              id: 'cad-38',
              name: ['Special Chain','Łańcuch Specjalny',],
              type: ['Special Chain','Łańcuch Specjalny',],
              img: ['cad-xxx',],
              clicked: false,
              models: [],
              descriptionHead: "Produkowany według określonych wymagań klienta.",
              descriptionBody: "Łańcuch z pokryciem galwanicznym, ze stali szlachetnej, ze specjalnymi oznaczeniami, specjalnymi cechami lub innymi podanymi charakterystykami lub specyfikacjami."
            },
                        
                        
                       
                      
               


        ]);

        return(
                <ChainzContext.Provider value={[chainz, setChainz]}>
                        {props.children}
                </ChainzContext.Provider>
        );

}
  
// devEd
 
