import { createContext, useState } from "react";


const SelectedBtnContext = createContext({
    selectedBtn: '',
    setSelectedBtn: () => {}
});


export const SelectedBtnProvider = ({children}) => {
    const [selectedBtn, setSelectedBtn] = useState(
        {selectedBtn}
    );
    
    return (
        <SelectedBtnContext.Provider value={{ selectedBtn, setSelectedBtn }}>
            {children}
        </SelectedBtnContext.Provider>
    );
}
export default SelectedBtnContext;

