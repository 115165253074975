import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import TaeLogo from '../vectors/TaeLogo';
import Meta from './Meta';
import Body  from './Body';
import Link from '@mui/material/Link';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListSubheader from '@mui/material/ListSubheader';
import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import { NoEncryption } from '@mui/icons-material';
import {NavLink} from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import TaeLogoGraysome from '../vectors/TaeLogoGraysome';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';

import SelectedBtnContext from '../providers/SelectedBtnProvider';

const drawerWidth = 240;
// const drawerWidth = '100%';

// switch

const label = { inputProps: { 'aria-label': 'Switch demo' } };
                            

// others

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function Page() {

const {selectedBtn, setSelectedBtn } = useContext(SelectedBtnContext);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [openProducts,setOpenProducts] = React.useState(false);
  const handleClickProducts = () => {
    setOpenProducts(!openProducts);
  };
  const [openServices,setOpenServices] = React.useState(false);
  const handleClickServices = () => {
    setOpenServices(!openServices);
  };
  const [openLanguages,setOpenLanguages] = React.useState(false);
  const handleClickLanguages = () => {
    setOpenLanguages(!openLanguages);
  };
  const [openChains,setOpenChains] = React.useState(false);
  const handleClickChains = () => {
    setOpenChains(!openChains);
  };
  const [openComponents,setOpenComponents] = React.useState(false);
  const handleClickComponents = () => {
    setOpenComponents(!openComponents);
  };
 
  // toggle selected button !!

//   const [selectedBtn, setSelectedBtn] = React.useState(-1);

//   useEffect(() => {
    
    
//     console.log(`show active ${selectedBtn}` );
    
    
//     });

  //darkmode
//   const [darkmode, setDarkmode] = React.useState(['lightmode']);

//   const handleToggleDarkmode = (value) => () => {
//     const currentIndex = darkmode.indexOf(value);
//     const newDarkmode = [...darkmode];

//     if (currentIndex === -1) {
//       newDarkmode.push(value);
//     } else {
//       newDarkmode.splice(currentIndex, 1);
//     }

//     setDarkmode(newDarkmode);
//   };


/// viewport dimensions !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  const [width, setWidth]   = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const updateDimensions = () => {
     
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
  };

//   useEffect(() => {
//       window.addEventListener("resize", updateDimensions);
      
//       console.log(`width state ${width}` );
//       console.log(`height state ${height}` );
//       return () => window.removeEventListener("resize", updateDimensions);
//   });
 

  return (

    <Box 
        sx={{ 
            display: 'flex', 
            overflow: 'hidden', 
            
        }}>
        <CssBaseline />

        {/* APPBAR */}

        <AppBar position="fixed" open={open} 
            sx={{
                boxShadow: 2, 
                
                height: '66px',
            }}>
            <Toolbar>


                {/* LOGO */}

                <Link 
                    component={NavLink} to='/home' underline="none"
                    onClick={()=>{setSelectedBtn(1000000)}}
                    sx={{ m: 1, height: "50px"}}
                    >
                    {<TaeLogo  />}
                </Link>
                
                {/* NAPIS */}

                <Typography variant="h6" noWrap  component="div"
                    sx={{ 
                        flexGrow: 1,
                        [theme.breakpoints.down('xs')]: {
                            fontSize: 15
                        },
                        }}>
                    TAE HWA POLAND
                </Typography>
                
                
                {/* MENU BUTTON */}
                       

                        <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={()=>{handleDrawerOpen(); updateDimensions()}}
                            sx={{
                                ...(open && { display: 'none' }) 
                            }}>
                            <MenuIcon />
                        </IconButton>
                       

            </Toolbar>
        </AppBar>


        {/* RENDERING */}
        <Main open={open}
            sx={{
                // overflow: 'scroll',
                flexGrow: 1,
    // padding: theme.spacing(3),
    marginRight: '1px'
    

            }}>
            
            <DrawerHeader />
            <Body />
            
        </Main>


        {/* MENU */}
        <Drawer 
        variant="persistent"
         anchor="right" 
         open={open}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                // height: 'auto',
                '& .MuiDrawer-paper': {
                    width: drawerWidth, 
                    
                    
                    [theme.breakpoints.down('sm')]: {
                        width: "100%"
                    },
                },
                // boxSizing: 'border-box',
                position:'absolute',
    
    // whiteSpace: 'nowrap',
            }}> 


            {/* MENU */}

            <Box
                sx={{
                    position: 'sticky', top: 0, zIndex: 22, bgcolor: 'background.paper',    
                }}>

                {/* top menu  */}

                <Box 
                    sx={{
                        height: '66px', width: '100%',
                        // display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center',
                        // flexGrow: 1,
                        boxShadow: 2, 
                    }}
                    >

                    {/* LOGO & napis GRAYSOME     */}

                    {/* { width < 700 ? 
                        <Link component={NavLink} to='/home' underline="none"
                            sx={{ m: 1, height: "50px" }} 
                            onClick={()=>{handleDrawerClose();setSelectedBtn(-1)}} >
                            {<TaeLogoGraysome  />}
                        </Link>
                        : 
                        <Link component={NavLink} to='/home' underline="none"
                            sx={{ m: 1, height: "50px", display: 'none' }} onClick={handleDrawerClose} >
                            {<TaeLogoGraysome  />}
                        </Link>
                    }

                    <Typography variant="h6" noWrap  component="div"
                        sx={{ 
                            flexGrow: 1, color:'#8b8b8b',
                            [theme.breakpoints.down('xs')]: {
                                fontSize: 15
                            },
                            [theme.breakpoints.up('sm')]: {
                                display: 'none'
                            },
                            }}>
                        TAE HWA POLAND
                    </Typography> */}

                    {/* TOP MENU    */}
                    <DrawerHeader 
                        sx={{display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center',}}
                        >

                        {/* HOME    */}

                        { width < 700 ? 
                        
                            <IconButton onClick={()=>{handleDrawerClose();setSelectedBtn(1000000)}} 
                                sx={{
                                    "&:hover": { color: '#EE1723' },
                                    ...( selectedBtn === 1000000 && { color: '#EE1723' }) ,
                                }}
                                component={NavLink} to='/home' 
                                
                                >
                                <HomeOutlinedIcon /> 
                            </IconButton>
                        : 
                            <IconButton onClick={()=>{setSelectedBtn(1000000)}}
                                sx={{
                                    "&:hover": { color: '#EE1723' },
                                    ...( selectedBtn === 1000000 && { color: '#EE1723' }) ,
                                }}
                                component={NavLink} to='/home' 
                               
                                >
                                <HomeOutlinedIcon  /> 
                            </IconButton>
                        }

                        

                        <Box sx={{ flexGrow: 1,  width: 'auto'}}><></></Box>  
                        
                        

                        {/* SEARCH    */}
                        {/* <Paper
                            component="form"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', 
                            width: 400 
                            }}
                            >
                            <IconButton sx={{ p: '10px' }} aria-label="menu">
                                <FingerprintIcon />
                            </IconButton>
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search Teahwa Poland"
                                inputProps={{ 'aria-label': 'search tae hwa poland' }}
                            />
                            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton sx={{ p: '10px' }} aria-label="directions">
                                <SettingsOutlinedIcon />
                            </IconButton>
                        </Paper>    */}
                        
                        {/* EXIT    */}
                        

                            <IconButton onClick={()=>{handleDrawerClose()}}
                                sx={{
                                    "&:hover": { color: '#EE1723' }, 
                                    
                                }}>
                                <CloseIcon /> 
                            </IconButton>
                        
                    </DrawerHeader>
                </Box>
            </Box>
                

            {/* MENU CONTENT */}

            <Box
                sx={{
                    // height: '100vh',
                    // minHeight: 'calc(100vh - 100px)',
                    // overflow: 'scroll'
                }}>

                <Box 
                    sx={{
                        height: 'auto',
                        minHeight: 'calc(100vh - 140px)',
                        
                    }}
                    >
                    
                    {/* LISTS */}

                    <List component="nav"                    
                        sx={{ 
                            bgcolor: 'background.paper',
                            
                        }}>

                        {/* ABOUT COMPANY !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  color: '#EE1723' !!!!!*/}

                        <ListItemButton 
                            sx={{ pt: 0, pb: 0, pl: 1, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                            ...( selectedBtn === 0 && { color: '#EE1723' }) ,
                            }} 

                            component={NavLink} to='/about'
                            onClick={()=>setSelectedBtn(0)}
                            // disabled
                            >
                       
                            { width < 700  ? <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="About"  onClick={handleDrawerClose}  /> 
                            :
                             <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="About" /> }
                                                
                        </ListItemButton>
                        <ListItemButton 
                            sx={{ pt: 0, pb: 0, pl: 1, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                            ...( selectedBtn === 1 && { color: '#EE1723' }) ,
                            }} 

                            component={NavLink} to='/contacts'
                            onClick={()=>setSelectedBtn(1)}
                            
                            >
                       
                            { width < 700  ? <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Contacts"  onClick={handleDrawerClose}  /> 
                            :
                             <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Contacts" /> }
                                                
                        </ListItemButton>


                        {/* PRODUCTS */}

                        <ListItemButton 
                        sx={{ pt: 0, pb: 0, pl: 1, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, }} 
                         onClick={()=>{handleClickProducts();setSelectedBtn(2)}}
                        
                         >
                            {/* color={selectedBtn === 2 ? "secondary" : "primary"} */}
                            <ListItemText  sx={{ fontSize: ".7rem" }} disableTypography primary="Products" /> 
                            {openProducts ? <ExpandLess sx={{ fontSize: "1rem", }} /> : <ExpandMore sx={{ fontSize: "1rem", color:"#EE1723", transform: "rotate(-90deg)" }} />}
                        </ListItemButton>

                        {/* CHAINS */}

                        <Collapse in={openProducts} timeout="auto" unmountOnExit>

                            <List component="div" disablePadding sx={{
                                // "&:hover": { color: '#EE1723' },
                            }}>
                                <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, }}
                                 onClick={handleClickChains}>
                                    <ListItemText sx={{ fontSize: ".7rem", }} disableTypography primary="CAD Dolzamet&trade; Chains" 
                                    // color='[theme.palette.tae]'
                                    /> 
                                    {openChains ? <ExpandLess sx={{ fontSize: "1rem",  }} /> : <ExpandMore sx={{ fontSize: "1rem", color:"#EE1723", transform: "rotate(-90deg)"  }} />}
                                    
                                </ListItemButton>

                                <Collapse in={openChains} timeout="auto" unmountOnExit>
                                    <List dense
                                        sx={{ bgcolor: 'background.paper', }}
                                        component="nav" subheader={
                                            <ListSubheader component="div" id="nested-list-subheader-chains" 
                                            sx={{ pl: 2, mt:1, mb:1, fontSize: ".7rem", color: "#8b8b8b", lineHeight: '12px' }} 
                                            >
                                            ROLLER CHAINS
                                            </ListSubheader>
                                        } 
                                        aria-labelledby="nested-list-subheader-chains">

                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                        ...( selectedBtn === 1081 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1081)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type B1" />  
                                        </ListItemButton>        
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                        ...( selectedBtn === 1091 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1091)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type B2" />  
                                        </ListItemButton>        
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                        ...( selectedBtn === 1101 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1101)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type B3" />  
                                        </ListItemButton>        
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                        ...( selectedBtn === 1111 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1111)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type A1" />  
                                        </ListItemButton>        
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                        ...( selectedBtn === 1121 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1121)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type A2" />  
                                        </ListItemButton>        
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                        ...( selectedBtn === 1131 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1131)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type A3" />  
                                        </ListItemButton>        
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                        ...( selectedBtn === 1141 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1141)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="B1-GL" />  
                                        </ListItemButton>        
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                        ...( selectedBtn === 1151 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1151)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="B2-GL" />  
                                        </ListItemButton>        
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                        ...( selectedBtn === 1161 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1161)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="B3-GL" />  
                                        </ListItemButton>        
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                        ...( selectedBtn === 1171 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1171)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type H" />  
                                        </ListItemButton>        
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                        ...( selectedBtn === 1181 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1181)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type H with Double Pitch" />  
                                        </ListItemButton>        
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                        ...( selectedBtn === 1191 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1191)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type A&amp;B with Double Pitch" />  
                                        </ListItemButton>        
                                        
                                    </List>  
                                    <List dense
                                        sx={{   bgcolor: 'background.paper', }}
                                        component="nav" subheader={
                                            <ListSubheader component="div" id="nested-list-subheader-chains" 
                                            sx={{ pl: 2, mt:1, mb:1, fontSize: ".7rem", color: "#8b8b8b", lineHeight: '12px' }} 
                                            >
                                            CONVEYOR ROLLER CHAINS
                                            </ListSubheader>
                                        } 
                                        aria-labelledby="nested-list-subheader-chains">

                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1,  }, 
                                        ...( selectedBtn === 1201 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1201)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type A&amp;B with Attachment" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1,  }, 
                                        ...( selectedBtn === 1211 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1211)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type A&amp;B with Extended Pins" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1221 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1221)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Accumulative STF" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1231 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1231)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Standard with Rubber Top" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1233 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1233)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Standard with Sharp Top" />  
                                        </ListItemButton> 

                                        
                                    </List>  
                                    <List dense
                                        sx={{   bgcolor: 'background.paper', }}
                                        component="nav" subheader={
                                            <ListSubheader component="div" id="nested-list-subheader-chains"
                                            sx={{ pl: 2, mt:1, mb:1, fontSize: ".7rem", color: "#8b8b8b", lineHeight: '12px' }} 
                                            >
                                            LEAF CHAINS
                                            </ListSubheader>
                                        } 
                                        aria-labelledby="nested-list-subheader-chains">
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1241 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1241)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type LL" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1251 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1251)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type AL" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1261 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1261)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type LH/BL" />  
                                        </ListItemButton> 
                                        
                                    </List>  
                                    <List dense
                                        sx={{   bgcolor: 'background.paper', }}
                                        component="nav" subheader={
                                            <ListSubheader component="div" id="nested-list-subheader-chains" 
                                            sx={{ pl: 2, mt:1, mb:1, fontSize: ".7rem", color: "#8b8b8b", lineHeight: '12px' }} 
                                            >
                                            AGRICULTURAL CHAINS
                                            </ListSubheader>
                                        } 
                                        aria-labelledby="nested-list-subheader-chains">

                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1271 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1271)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type BX" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1272 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1272)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type S55X" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1281 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1281)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type S55X with Attachment" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1291 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1291)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type S55X with Strenghtened Attachment" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1301 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1301)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type S55X for Harvesting Machines" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1302 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1302)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type S55X with Angle Bar" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1311 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1311)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Corn Harvesting Type" />  
                                        </ListItemButton> 
                                        
                                        
                                    </List>  
                                    <List dense
                                        sx={{   bgcolor: 'background.paper', }}
                                        component="nav" subheader={
                                            <ListSubheader component="div" id="nested-list-subheader-chains" 
                                            sx={{ pl: 2, mt:1, mb:1, fontSize: ".7rem", color: "#8b8b8b", lineHeight: '12px' }} 
                                            >
                                            CONVEYOR CHAINS
                                            </ListSubheader>
                                        } 
                                        aria-labelledby="nested-list-subheader-chains">

                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1321 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1321)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type FV" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1331 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1331)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type FVT" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1341 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1341)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type FVC" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1351 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1351)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type FVR " />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1361 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1361)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type FV with Angle Attachments" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1371 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1371)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type M" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1381 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1381)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type MT" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1391 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1391)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type MC" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1401 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1401)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type MR" />  
                                        </ListItemButton> 
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1411 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1411)}}
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Type M with Angle Attachments" />  
                                        </ListItemButton> 
                                        
                                    </List>  
                                    <List dense
                                        sx={{   bgcolor: 'background.paper', }}
                                        component="nav" subheader={
                                            <ListSubheader component="div" id="nested-list-subheader-chains" 
                                            sx={{ pl: 2, mt:1, mb:1, fontSize: ".7rem", color: "#8b8b8b", lineHeight: '12px' }} 
                                            >
                                            SPECIAL CHAINS
                                            </ListSubheader>
                                        } 
                                        aria-labelledby="nested-list-subheader-chains">
                                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                        ...( selectedBtn === 1421 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(1421)}} 
                                        disabled
                                        >
                                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Special Chains" />  
                                        </ListItemButton> 
                                        
                                    </List>  
                                    
                                </Collapse>
                                
                                {/* COMPONENTS HA */}

                                <ListItemButton 
                                sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, } , }}
                                 onClick={handleClickComponents}
                                 disabled
                                 >
                                    <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="LG HA Components"
                                    disabled
                                    /> 
                                    {openComponents ? <ExpandLess sx={{ fontSize: "1rem" }} /> : <ExpandMore sx={{ fontSize: "1rem", color:"#EE1723", transform: "rotate(-90deg)"  }}
                                    disabled
                                    />}
                                </ListItemButton>
                                <Collapse in={openComponents} timeout="auto" unmountOnExit
                                disabled
                                >

                                </Collapse>



                            </List>
                        </Collapse>

                        {/* SERVICES */}

                        <ListItemButton sx={{ pt: 0, pb: 0, pl: 1, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, }}
                        onClick={()=>{handleClickServices();setSelectedBtn(3)}}
                        // color={selectedBtn === 3 ? "secondary" : "primary"}
                        >
                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Services" /> 
                            {openServices ? <ExpandLess sx={{ fontSize: "1rem" }}/> : <ExpandMore sx={{ fontSize: "1rem", color:"#EE1723", transform: "rotate(-90deg)" }}/>}
                        </ListItemButton>

                        <Collapse in={openServices} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                            <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                            ...( selectedBtn === 300 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(300)}}
                            disabled
                             >
                                <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Galvanizing" 
                                
                                />

                            </ListItemButton>
                            <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                            ...( selectedBtn === 310 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(310)}}
                             disabled
                             >
                                <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="CNC Laser" />

                            </ListItemButton>
                            <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                            ...( selectedBtn === 320 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(320)}}
                             disabled
                             >
                                <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Heat Treatment" />

                            </ListItemButton>
                            {/* <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                            ...( selectedBtn === 330 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(330)}} 
                            disabled
                            >
                                <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Industrial Washing" />

                            </ListItemButton> */}
                            
                            
                            </List>
                        </Collapse>
                    </List>
                
                    <Divider />


                    {/* SETTINGS */}

                    <List dense
                        sx={{   bgcolor: 'background.paper' }}
                        component="nav" subheader={
                            <ListSubheader component="div" id="nested-list-subheader-settings">
                            Settings
                            </ListSubheader>
                        } 
                        aria-labelledby="nested-list-subheader-settings">

                        <ListItemButton onClick={handleClickLanguages} 
                        sx={{ pt: 0, pb: 0, pl: 1, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1,  }, }} 
                        // disabled
                        >
                            <ListItemText 
                            sx={{ fontSize: ".7rem" }} disableTypography primary="Language"
                            
                            />
                             {openLanguages ? <ExpandLess sx={{ fontSize: "1rem" }} /> : <ExpandMore sx={{ fontSize: "1rem", color:"#EE1723", transform: "rotate(-90deg)" }} disabled/>}
                        </ListItemButton>
                        
                        {/* LANGUAGES */}

                        <Collapse in={openLanguages} timeout="auto" unmountOnExit>
                        
                            <List dense component="div" disablePadding
                                
                            >
                                <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                // ...( selectedBtn === 701 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(701)
                                }}
                                // disabled
                                >                                
                                    <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="English" />
                                </ListItemButton>
                                <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                // ...( selectedBtn === 702 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(702)
                                }}
                                disabled
                                >
                                    <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Polish" />
                                </ListItemButton>
                                <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1, }, 
                                // ...( selectedBtn === 703 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(703)}}
                                }}
                                disabled
                                >
                                    <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Korean" />
                                </ListItemButton>
                                <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                // ...( selectedBtn === 704 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(704)}}
                                }}
                                disabled
                                >
                                    <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="German" />
                                </ListItemButton>
                                <ListItemButton sx={{ pt: 0, pb: 0, pl: 2, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, 
                                // ...( selectedBtn === 704 && { color: '#EE1723' }) ,}} onClick={()=>{setSelectedBtn(704)}}
                                }}
                                disabled
                                >
                                    <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Turk" />
                                </ListItemButton>
                                
                            </List>
                        </Collapse>

                        {/* DARK MODE */}

                        <ListItemButton  sx={{ pt: 0, pb: 0, pl: 1, pr: 1, "&:hover": { borderBottom: '1px solid #EE1723', boxShadow: 1 }, }}
                        disabled
                        >
                            <ListItemText sx={{ fontSize: ".7rem" }} disableTypography primary="Dark Mode" />
                            
                            
                                <div>
                                    <Switch {...label} defaultChecked size="small" disabled/>
                                    
                                </div>
                            

                                {/* <Switch 
                                edge="end" color='tae' size="small"
                                onChange={handleToggleDarkmode('darkmode')}
                                checked={darkmode.indexOf('darkmode') !== -1}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-darkmode',
                                }}
                                
                                /> */}

                            
                        </ListItemButton>

                    </List>

                </Box>

                {/* FOOTER MENU */}

                <Box 
                    sx={{
                        pt: '3px',  bgcolor: 'background.paper', pl: 4, 
                        // height: 'auto'
                    }}>
                    <Meta />    
                </Box>            

            </Box>

        </Drawer>
      
    </Box>
  );
}
