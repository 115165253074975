import * as React from 'react';
import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Typography, Box, Card, CardHeader, CardContent, Grid, Paper, Container, CardMedia, CardActions, Button, IconButton, Link,
List, ListItem, ListItemButton, ListItemText, ListSubheader, Menu, MenuItem, Badge, ButtonGroup, Divider, Avatar  } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { BrowserRouter,
  Routes,
  Switch,
  Route,
  NavLink,
  
  } from "react-router-dom";
  import CallIcon from '@mui/icons-material/Call';
  import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import pol from '../img/contact/pol.svg';
// import krs from '../img/contact/krs.jpg';
import krs from '../img/contact/krs.svg';
import mal from '../img/contact/mal.jpg';
import fem from '../img/contact/fem.jpg';
import ela from '../img/contact/ela.jpg';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Linking} from 'react';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import CheckIcon from '@mui/icons-material/Check';
import VerifiedIcon from '@mui/icons-material/Verified';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


// import cad01 from '../vectors/chains/cad01.svg';
// import cad02 from '../vectors/chains/cad02.svg';
// import cad03 from '../vectors/chains/cad03.svg';
import ge from '../img/home/geo_small.jpg';
import dz from '../img/home/CAD.svg';
import lg from '../img/home/LG.svg';
import ta from '../img/home/TAE.svg';
import taw from '../img/home/TAEw.svg';


import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SchoolIcon from '@mui/icons-material/School';
import {ChainzContext, ChainzProvider }from '../providers/ChainzProvider';
import SelectedBtnContext from '../providers/SelectedBtnProvider';

import {ThemeContext, themes} from '../providers/ThemeProvider';


import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";

import {SearchBar, filterDataChainz} from './Search';
import { withTheme } from '@emotion/react';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

// -----------------------------
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// -----------------------------
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
import PropTypes from 'prop-types';
// ---------------------------------------
import { purple, red } from '@mui/material/colors';

const primarysss = red[500]; // #f44336

const styles = {

// '&::-webkit-scrollbar': {width: '40px', color: 'black'},
// '&::WebkitScrollbar': {display: 'visible',width: '5px'},
// '&::-webkit-scrollbar-track': {boxShadow: 'rgb(238 23 35)'},
// '&::-webkit-scrollbar-thumb': {
//   background: 'gray' 

// },


// '&::-webkit-scrollbar-thumb:hover': {
//   background: 'black'
// },

};
const stylemodal = {
  position: 'absolute',
  width: 'auto',
  height: 'auto',

};



{/* sx={{color: '#EE1723'}} */}
// const chainz = [
//   {
//     name: 'Type B1',
//     type: 'Roller Chain',
//     img: 'cad01',
    
//     model: [
//       {no: '08B-1', P: '12.7', b1min: '7.75', spec: ''},
//       {no: '10B-1', P: '15.875', b1min: '9.65', spec: '10'},
//       {no: '12B-1', P: '19.05', b1min: '11.68', spec: '12'},
//     ] 
    
//   },
//   {
//     name: 'Type B2',
//     type: 'Roller Chain',
//     img: 'cad02',
    
//     model: [
//       {no: '08B-2', P: '12.7', b1min: '7.75', spec: ''},
//       {no: '10B-2', P: '15.875', b1min: '9.65', spec: '10'},
//       {no: '12B-2', P: '19.05', b1min: '11.68', spec: '12'},
//     ] 
    
//   },
//   {
//     name: 'Type B3',
//     type: 'Roller Chain',
//     img: 'cad03',
    
//     model: [
//       {no: '08B-3', P: '12.7', b1min: '7.75', spec: ''},
//       {no: '10B-3', P: '15.875', b1min: '9.65', spec: '10'},
//       {no: '12B-3', P: '19.05', b1min: '11.68', spec: '12'},
//     ] 
    
//   },
  
 
// ];


// const preventDefault = (event) => event.preventDefault(); // bez tego cos Link nie dziala

// const BootstrapTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} arrow classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.arrow}`]: {
//     color: theme.palette.common.black,
//   },
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.black,
//   },
// }));


const preventDefault = (event) => event.preventDefault(); 



function Chains () {

  const [type, setType] = React.useState('All');
  const handleType = (event)=> {
    // console.log(event.target.value);
    setType(event.target.value); 
  }
  const { themex, setThemex } = useContext(ThemeContext);
  
  const [chainz, setChainz] = useContext(ChainzContext);
  const [searchQueryChainz, setSearchQueryChainz] = useState("");
  const [anchorElx, setAnchorElx] = React.useState(null);
  const [anchorEly, setAnchorEly] = React.useState(null);
  
  const [currentIndex, setCurrentIndex] = React.useState(undefined);
  const [currentIndexy, setCurrentIndexy] = React.useState(undefined);
  
  const openx = Boolean(anchorElx);
  const openy = Boolean(anchorEly);
  
  const handleOpenx = (id) => (event) => {
    setAnchorElx(event.currentTarget);
    setCurrentIndex(id);
    // console.log(currentIndex);
  };
  
  const handleClosex = () => {
    setAnchorElx(null);
  };
  const handleClosey = () => {
    setAnchorEly(null);
  };


  //  -----------------------------------------------  
  
  
  
  
  //  -----------------------------------------------
  const [haj, setHaj] = React.useState(0);
  const lohi = ()=> {
    const l = document.getElementById('lolo');
    const hiii = l.offsetHeight;

    // console.log(hiii);
    setHaj(hiii);
  }
  
  const handleOpenAll = ()=> {

  }
  
  
  // }
  // const handleOpeny = (index) => (event) =>{
  //   console.log("index in: "+item.id)
  //   openy && index === currentIndexy ? 

  //     setAnchorEly(undefined) || setCurrentIndexy(undefined)
  //     :
  //     setAnchorEly(event.currentTarget) || setCurrentIndexy(index) 
  //   ;
  //   chainz[index].clicked = !chainz[index].clicked; 
    
  
  // }
  // const handleOpeny = (in) => (event) =>{

  //   openy && id === currentIndexy ? 

  //     setAnchorEly(undefined) || setCurrentIndexy(undefined)
  //     :
  //     setAnchorEly(event.currentTarget) || setCurrentIndexy(id) 
  //   ;
  //   id.clicked = !id.clicked; 
  
  // }
  
  
  //  -----------------------------------------------
  const [color, setColor] = React.useState('secondary');
  
    const onHoverOver = () => {
    //  setVariant('outlined');
    //  setColor('tae')
    };
    const onHoverOut = () => {
    //  setVariant('text');
     setColor('secondary')
    };
  const {selectedBtn, setSelectedBtn } = useContext(SelectedBtnContext);

  // const [searchType, setSearchType]
  const dataFiltered = filterDataChainz(searchQueryChainz, chainz, type);
//  --------------------- ------------------- ----------------------- ---------
  const [state, setState] = React.useState(false);
  const handleState = (event, newState) => {
    

    newState === null ? setState(state) : setState(newState);
    chainz.filter((i)=> i.clicked = newState);
   
    
    // console.log(state);
  };
  const handleOpeny =  (id) => (event) =>{
   
    setState(null);
    openy && id === currentIndexy ? 

      setAnchorEly(undefined) || setCurrentIndexy(undefined)
      :
      setAnchorEly(event.currentTarget) || setCurrentIndexy(id) 
    ;
    
    chainz.filter((i)=>{
      i.id === id ? i.clicked = !i.clicked : i.clicked = i.clicked});
    
  }
//  --------------------- ------------------- ----------------------- ---------
const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };


  const greeni = themex.greenisz;
  const blui = themex.bluisz;


//  --------------------- ------------------- ----------------------- ---------



function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // const { themex, setThemex } = useContext(ThemeContext);

  // const greeni = themex.greenisz;
  // const blui = themex.bluisz;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



//  --------------------- ------------------- ----------------------- ---------
  return (


    // -------- CHAINS JAZDA

<div>
  <section id='chainz'>

  <Container  >
      <Card sx={{ minWidth: 275, boxShadow:'none',  }}  >
          
                {/* MAIN CARD----------------- */}
        <CardContent>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
      
          </Typography>
          <Typography variant="h4" component="div" >
           CAD&trade; Dolzamet Chains
            
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Chain Products
          </Typography>
        
        </CardContent>

        <Container>
          <CardActions sx={{display: 'flex', flexDirection: 'row', 
          justifyContent: 'end', 
          
          }}>
            <Box sx={{
              // minWidth: '200px', 
              // mr: '20px'
              }}>
              <FormControl sx={{ 
                  m: 1,
                //  minWidth: '120px' 
                 }} 
                 size="small">
                {/* <InputLabel >Age</InputLabel> */}
                <Select
                  
                  // value={type}
                  defaultValue={'All'}
                  vaule={type}
                  onChange={handleType}
                >
                  
                  <MenuItem value={'All'}>All</MenuItem>
                  <MenuItem value={'Roller Chain'}>Roller Chain</MenuItem>
                  <MenuItem value={'Conveyor Roller Chain'}>Conveyor Roller Chain</MenuItem>
                  <MenuItem value={'Leaf Chain'}>Leaf Chain</MenuItem>
                  <MenuItem value={'Agricultural Chain'}>Agricultural Chain</MenuItem>
                  <MenuItem value={'Conveyor Chain'}>Conveyor Chain</MenuItem>
                  <MenuItem value={'Special Chain'}>Special Chain</MenuItem>
                </Select>
              </FormControl>
              
            </Box>

            <Box sx={{
              width: 'auto',
              flexGrow: '1'
            }}>
              
              <SearchBar searchQuery={searchQueryChainz} setSearchQuery={setSearchQueryChainz} />
            </Box>
{/* TOGGLE`` */}
            <Box sx={{
              // minWidth: '80px', pl: '20px'
              }}>
              <Stack spacing={2} alignItems="end">
                <ToggleButtonGroup 
                value={state}
                size="small"
                exclusive
                onChange={handleState}
                //  {...expandColaplseControl} 
                
                 >
                  <ToggleButton value={true}>
                    <UnfoldMoreIcon />
                  </ToggleButton>
                  <ToggleButton value={false}>
                    <UnfoldLessIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
                
              </Stack>
              
            </Box>

           
           
          </CardActions>
          
        </Container>

            <br />

            {/* CARDZZZZZZZZZZZZZZZZZZZZ           ------------- */}
        <Container>


          {/* <Box sx={{
            display: 'flex', justifyContent: 'center',
            mb: 5, 
            }}>
            <SearchBar searchQuery={searchQueryChainz} setSearchQuery={setSearchQueryChainz} />
          
          </Box> */}
          
          <CardContent>
            <Grid container rowSpacing={{xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
              sx={{
                display: 'flex', flexDirection: 'row'
                // , justifyContent: 'center', 
              }}>
            
              { dataFiltered.map((item) => {

                // const isSelected = openy && id === currentIndexy;
                
               
                const hi = 'auto';
                const lo = 0;
  
                  return(

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} 
                      key={item.id} 
                      sx={{
                       
                        display: 'flex', flexDirection: 'column', 
                        // justifyContent: 'flex-start', 
                      }}>

                      <Card sx={{ 
                        // "&:hover": {
                        //   boxShadow: '0px 2px 1px -1px rgb(238 23 35 ), 0px 1px 1px 0px rgb(238 23 35 ), 0px 1px 3px 0px rgb(238 23 35 )',
                        // "&& button":
                        //   { color: '#EE1723' }
                        // } 
                        // "&:hover": {
                        //   boxShadow: '0px 2px 1px -1px rgb(238 23 35 / 20%), 0px 1px 1px 0px rgb(238 23 35 / 14%), 0px 1px 3px 0px rgb(238 23 35 / 12%)',
                        // "&& button":
                        //   { color: '#EE1723' }
                        // } 
                        }}>
                      
                        <CardHeader sx={{ 
                          borderBottom: '1px solid rgb(238 23 35)'
                          }}
                          title={item.name[0]}
                          subheader={item.type[0]} 
                          action={
                            <>
                            <IconButton aria-label="specifications"
                              size="small"
                              color={color}
                              onMouseOver={()=>{onHoverOver()}}
                              onMouseOut={()=>{onHoverOut()}}
                              variant="text"
                              onClick={handleOpenx(item.id)}
           

                           
                            >
                              <InfoOutlinedIcon />
                            </IconButton>
                            <IconButton aria-label="list"
                              
                              onClick={
                                handleOpeny(item.id) }
                            >
                              <MoreVertIcon />
                            </IconButton>
                            
                            
                            </>
                          }  
                          
                        />

                     
                        <CardContent 
                          // id='scroll-content'
                          sx={[{ 
                          display: 'flex', flexFlow: 'wrap', 
                          justifyContent: 'center',
                          // alignContent: 'center', alignItems: 'center',

                          // backgroundColor: '#EE1723',
                          // color: '#EE1723', 
                          // backgroundColor: 'hsl(357, 86%, 51%, 15%)',
                          backgroundColor: '#eeeeee',
                          // backgroundColor: 'hsl(0, 0%, 0%, 17%)',
                          color: 'hsl(0, 0%, 0%, 38%)', 
                           p: 0 , mt: 0, pb: 0, mb: 0,
                          
                        
                          height: item.clicked ? hi : 0,
                          

                          
                          transition: item.clicked ? 'height 667ms ease' : 'height 667ms ease',
                          animation: item.clicked ? 'showContent 667ms ease-in-out both' : 'hideContent 667ms ease-in-out both',
                          // overflow: 'auto', 

                          // borderBottom: '1px solid rgb(238 23 35)',
                          
                          
                          },styles] }
                            
                          >
                          
                         
                                <Box 
                                  // id='sticky-content'
                                  
                                  sx={{
                                  
                                  display: 'flex', flexFlow: 'wrap',
                                  justifyContent: 'center', 
                                  // alignItems: 'center',
                                  // alignItems: 'flex-end',
                                  width: '98%',  
                                  // backgroundColor: '#EE1723',
                                  // backgroundColor: 'hsl(0, 0%, 0%, 17%)',
                                  
                                  // marginRight: '20px',
                                  paddingTop: '10px', pr:'10px', pl:'10px', 
                                  
                                }}
                                >
                                   {item.models[0]
                                   ? 
                                    // <Typography variant="caption" >
                                  
                                    //  {"Standard models: "}
                                    //  {" "}
                                    // </Typography> 
<TableContainer component={Paper} sx={{ width: '50vw',

              flexGrow: '1', overflow: 'auto',}}>


 <Table sx={{  }} size="small">
 
   <TableHead stickyheader='true' >
     <TableRow sx={{
       align: 'right', 
     }}>
      <TableCell sx={{ width: 'auto',
        maxWidth: '100%',
        maxHeight: '20px'
      }}>
        Model
      </TableCell>
      {
       item.models.filter((model, index) =>  model.name != null && index == 0 ).
       map((model, index)=> model.specs.map((spec,index) => (
        <TableCell key={index} sx={{ width: 'auto',
         maxWidth: '100%',
         maxHeight: '20px'
         }}>
          {spec.name} <span style={{color: 'gray'}}>{spec.unit}</span>
        </TableCell>
       )))
       }
     
     </TableRow>

   </TableHead>

   <TableBody>
       {
         item.models.map((model, index) => (
           <TableRow 
            hover  
            sx={{ 
             '&:last-child td, &:last-child th': { border: 0 }, 
             '&:nth-of-type(odd)': {
               backgroundColor: '#eeeeee', 
             },
               align: 'right', 
               maxWidth: '100%',
                 maxHeight: '20px'
             }} key={index}
           > 

             <TableCell component="th" scope="row" sx={{fontWeight: 'bold', width: 'auto',
              maxWidth: '100%',
              maxHeight: '20px'
              }} >
               {model.name}
             </TableCell>
             {
               
               model.specs.map((spec, index) =>  (
                 
                 <TableCell  component="th" scope="row" key={index}  sx={{ width: 'auto', 
                 maxWidth: '100%',
                 maxHeight: '20px'
                 }} >
                    {
                    Array.isArray(spec.measures)
                      ? spec.measures.map((s, i)=> (
                        <span key={i}>
                          {s}<span style={{opacity: 0}}>_</span>
                        </span>
                      )) :
                      <span key={index}>
                        {spec.measures} 
                      </span>
                    }
                    {/* {
                      
                      
                        spec.measures.array.forEach(element => {
                          <span>{element}</span>
                        })
                        
                    } */}
                  </TableCell>

                  
                  )

                  
               )
               
               
               
             }
             {/* {
               
               model.specs.map((spec, index) => (
               <TableCell component="th" scope="row" key={index}>
                 {spec.measures} 
               </TableCell>
               ))
             } */}
           </TableRow>
         ))
       }
       
       
     </TableBody>

 </Table>

    
</TableContainer>

                                  :
                                  <Typography variant="caption" >
                                  
                                      {"No standard models! "}
                                    </Typography> 

                                   }
                                  
                                  
                                  
                                 
                                </Box>
                                {/* {item.models.map((it, index) => {
                                      return ( */}
                                {/* <Box 
                                  id='lolo'
                                  sx={{ 
                                    display: 'flex', 
                                    flexWrap: 'wrap', flexDirection: 'column',
                                    color: 'hsl(0, 0%,  0%, 83%)',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                          
                                    p:0,
                                    
                                    paddingTop: '0px',

                              
                                  width: '100%',

                                  }}
                                  > */}
                                   

                                    {/* <List
                                      subheader={
                                        <ListSubheader component="div" id="nested-list-subheader">
                                          it.name
                                        </ListSubheader>}
                                    >
                                      

                                        <ListItem disablePadding>
                                          lalala
                                        </ListItem>
                                     
                                      
                                    </List>   */}

                                {/* </Box> */}
                                      {/* )
                                    }} */}

                                  <Grid container 
                                  rowSpacing='1' 
                                  columnSpacing='2'
                                  


                                    sx={{
                                      display: 'flex', flexDirection: 'row', justifyContent: 'center',
                                      
                                      pt: 0,
                                    }}>


                                  
                                      
                                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} 
                                      // key={it.name} 
                                      sx={{
                                        padding: 1
                                       
                                      }}>
                                            {/* <List dense sx={{p:0}} >
                                      {item.models.map((it, index) => {
                                    
                                    return(

                                        <ListItemButton disablepadding="true" key={it.name} >
                                          <ListItem>

                                            {it.name} &nbsp; <Divider orientation="vertical"  flexItem /> &nbsp; {item.type[0]} &nbsp; <Divider orientation="vertical"  flexItem /> &nbsp; {item.name[0]}
                                          </ListItem>
                                        </ListItemButton>
                                     );
                                    })}
                                      
                                    </List>  */}
       
{/* <TableContainer component={Paper} sx={{maxWidth: '600px', overflow: 'auto' }}>
 
  <Table sx={{ }} size="small">
  
    <TableHead stickyheader='true' >
      <TableRow sx={{
        align: 'right',
      }}>
        <TableCell>Model</TableCell>
       {
        item.models.filter((model, index) =>  model.name != null && index == 0 ).
        map((model, index)=> model.specs.map((spec,index) => (
          <TableCell key={index}>{spec.name} <span style={{color: 'gray'}}>{spec.unit}</span></TableCell>
        )))
        }
      
      </TableRow>

    </TableHead>

    <TableBody>
        {
          item.models.map((model, index) => (
            <TableRow sx={{ 
              '&:last-child td, &:last-child th': { border: 0 }, 
              '&:nth-of-type(odd)': {
                backgroundColor: '#eeeeee',
              },
                align: 'right',
              }} key={index}
            > 

              <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}} >
                {model.name}
              </TableCell>
              {
                
                model.specs.map((t, index) => (
                <TableCell component="th" scope="row" key={index}>
                  {t.value}
                </TableCell>
                ))
              }
            </TableRow>
          ))
        }
        
        
      </TableBody>

  </Table>
     
</TableContainer> */}

                                    
                                          {/* <Typography 
                                            variant="button"
                                            sx={{
                                             
                                              display: 'block',
                                              minWidth: '70px',
                                              textAlign: 'center'
                                            }}>
                                              {it.name}
                                          </Typography> */}

                                   
                                      </Grid>     
                                     
 
                                  </Grid>      
                                

                      

                        </CardContent>

                        <CardActions sx={{display: 'flex', flexDirection: 'row',
                            backgroundColor: '#eeeeee',
                        
                        }}>

                          {/* <IconButton 
                           
                            onClick={
                               handleOpeny(item.id) 
                            } 

                            sx={{  }}>
                            <InfoOutlinedIcon  
                              
                              sx={{  }} />
                          </IconButton>

                          <Box sx={{flexGrow: 1,}}><></></Box>

                          <Button
                            size="small"
                            color={color}
                            onMouseOver={()=>{onHoverOver()}}
                            onMouseOut={()=>{onHoverOut()}}
                            variant="text"
                        
                            onClick={handleOpenx(item.id)}
                            
                            >
                              DETAILS
                          </Button> */}
  {/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------- */}                         
  
                          <Modal

                            open={openx && item.id === currentIndex}
                            anchorel={anchorElx} 
                            onClose={handleClosex}


                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                              timeout: 500,
                            }}

                            sx={{
                              display: 'flex',
                              p: 1,
                              alignItems: 'center',
                              justifyContent: 'center',
                              // backgroundColor: 'white',
                            }}

                            disableEnforceFocus 
                          >
                            <Box sx={{ width: '100%', height: '100%',
                              position: 'absolute',
                              top: '66px',
                              left: '0px',
                              // transform: 'translate(-50%, -50%)',
                              // width: 400,
                              bgcolor: 'background.paper',
                              // border: '2px solid #000',
                              boxShadow: 24,
                              p: 4,
                          }}>


      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" centered
          textColor='inherit'
               indicatorColor='inherit'
          // '#EE1723'
        >
          <Tab label="Drawing" {...a11yProps(0)} />
          <Tab label="Specifications" {...a11yProps(1)} />
          <Tab label="Pictures" {...a11yProps(2)} 
          // disabled 
          />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        Technical Drawing of {item.name[0]}
        <span style={{color: `${themex.fillx}`}}>.GRRRRRRRRRRRRRRRRR</span>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        Complete Specifications of {item.name[0]}
      </TabPanel>
      <TabPanel value={tabValue} index={2} >
        Pictures of {item.name[0]}
      </TabPanel>
    </Box>
                            {/* <Box 
                            sx={stylemodal}
                            >

                             <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Text in a modal
                                  </Typography>
                                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                                  </Typography>
                                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    {item.name}
                                  </Typography>
                              <img
                                        src={require('../img/certs/' +
                                        item.img +
                                        '.jpg')} alt='foto' className='cert-img'
                                        onClick={handleClosex}
                                      />
                            </Box> */}
                          </Modal>
 {/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                        </CardActions>
                        
                    

                      </Card>

                      <br />
                    
                    </Grid>
                  );})}


          
            </Grid>               
          </CardContent>
        </Container>




      </Card>
  </Container>
  <br />                    
  <br />                    
  </section>





</div>                  
    
  )
}

export default Chains