
import './App.css';
import Head from './tree/Head';
import Body from './tree/Body';
import Meta from './tree/Meta';
import Page from './tree/Page';
import React, { useEffect, useState, useMemo, createContext, useContext } from "react";
import SelectedBtnContext from './providers/SelectedBtnProvider';

import { BrowserRouter,
  Routes,
  Switch,
  Route,
  Link} from "react-router-dom";

import {  ChainzProvider } from './providers/ChainzProvider';
import { ThemeContext, themes } from './providers/ThemeProvider';

// import Search from './contents/Search';

function App() {
  const [ themex, setThemex ] = useState(themes.lajtexxx);
  const [selectedBtn, setSelectedBtn] = useState(1000000);
  // const [chainz, setChainz] = useState(ChainzProvider);
  const value = useMemo(
    // ()=> ({ language, setLanguage }), [ language, setLanguage ],
    // ()=> ({ themex, setThemex }), [ themex, setThemex ],
    // ()=> ({ menu, setMenu }), [ menu, setMenu ],
    ()=> ({ selectedBtn, setSelectedBtn }), [ selectedBtn, setSelectedBtn ],
    );

    // useEffect(() => {
    
    
    //   console.log(`show active ${selectedBtn}` );
      
      
    //   });  


  return (
    
    <ThemeContext.Provider value={{themex, setThemex }}>
      <BrowserRouter>

      <ChainzProvider>
      <SelectedBtnContext.Provider value={{selectedBtn, setSelectedBtn}}>  

        <div className="App">
          <Page  />  
        </div>
      </SelectedBtnContext.Provider> 

      </ChainzProvider>
      
      
      </BrowserRouter>
      </ThemeContext.Provider>
    
    
  );
}

export default App;
