import React, { createContext, useState } from "react";

export const themes = {

    
    darkezzz: {
        // all
        textColor: "white",
        backbroundColor: "black",
        // custom logo
        fillx: "#EE1723",
        strokex: "#EE1723", 
        // chain drawings
        filly: "white",
        strokey: "white", 
        //  trade mark logo
        fillzfil0: "#FEFEFE",
        fillzfil1: "#000062",
        fillzfil2: "#811214",
        fillzfil3: "#272727",
        strokezstr0: "#000062", 
        strokezstr1: "18", 
        fontezfnt0: "bold",
        fontezfnt1: "329px",
        fontezfnt2: "Arial", 
        greenisz: "#52BE80",
        bluisz: "#5DADE2"
    },
    lajtexxx: {
        //all
        textColor: "black",
        backbroundColor: "white",
        // custom logo
        fillx: "#EE1723",
        strokex: "#EE1723", 
        // chain drawings
        filly: "black",
        strokey: "black", 
        // trade mark logo
        fillzfil0: "#FEFEFE",
        fillzfil1: "#000062",
        fillzfil2: "#811214",
        fillzfil3: "#272727",
        strokezstr0: "#000062", 
        strokezstr1: "18", 
        fontezfnt0: "bold",
        fontezfnt1: "329px",
        fontezfnt2: "Arial",
        greenisz: "#52BE80",
        bluisz: "#5DADE2"
        
    },
};

export const ThemeContext = createContext({
    themex: themes.lajtexxx,
    setThemex: () => {}
  });

// =================================
