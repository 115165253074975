import React from 'react';

import TaeLogo from '../vectors/TaeLogo';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Typography, AppBar, Toolbar, Link, Box, Button, Popover } from '@mui/material';


function Head() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
  return (
    <>

    <Box sx={{flexGrow: 1}}>
        <AppBar 
        position='fixed'
        // sx={{
        //     backgroundColor: "rgba(255,255,255,0.98)",
            
        //     "&:hover":  { borderBottom: '20px solid #EE1723'  },
    
        //     }}
       
        >
            <Toolbar sx={{
            // backgroundColor: "yellow",
            
            }}>

                
                <Link href="#" underline="none" color='secondary'
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ 
                    // "&:hover": { transform: 'scaleY(1.183)' }, 
                    m: 1, height: "50px", width: "50px"
                }}>
                    {<TaeLogo  />}
                </Link>


                <Popover
                    id="mouse-over-popover"
                    sx={{
                    pointerEvents: 'none', height: '300px', width: '300px'
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                    variant='caption'
                >
                    <Typography sx={{ p: 1, fontWeight: 8, fontSize: 10 }}>Home Page
                    </Typography>
                </Popover>

                <Typography variant='h5' gutterBottom component="div" sx={{
                    marginBottom: 0, paddingTop: 0, fontWeight: 400,
                    display: {xs: 'none', md: 'block'},
                    borderTop: "4px solid rgba(255,255,255,0)"
                    }} >
                TAEHWA POLAND
                </Typography>

                <Box sx={{flexGrow: 1}}
                />

                <Link href="#" underline="none" color='secondary'
                variant="h6" sx={{ "&:hover": {
                    // background: "#ededed",
                    // color: "#EE1723",
                    
                    borderBottom: "4px solid #EE1723",
                    
                }, borderBottom: "4px solid rgba(255,255,255,0)", m: 1, fontWeight: 8,
                display: {xs: 'none', sm: 'block'} }}>
                    {'About'}
                </Link>
                <Link href="#" underline="none" color='secondary'
                variant="h6" sx={{ "&:hover": {
                    // background: "#ededed",
                    // color: "#EE1723",
                    
                    borderBottom: "4px solid #EE1723"
                }, borderBottom: "4px solid rgba(255,255,255,0)", m: 1, fontWeight: 8,
                display: {xs: 'none', sm: 'block'}
                }}>
                    {'Products'}
                </Link>
                <Link href="#" underline="none" color='secondary'
                variant="h6" sx={{ "&:hover": {
                    // background: "#ededed",
                    // color: "#EE1723",
                    
                    borderBottom: "4px solid #EE1723"
                }, borderBottom: "4px solid rgba(255,255,255,0)", m: 1, fontWeight: 8,
                display: {xs: 'none', sm: 'block'}
                }}>
                    {'Services'}
                </Link>

                <Box sx={{
                    width: 20,
                    '&:hover': {
                    backgroundColor: 'primary.main',
                   
                    }
                }}
                />

                <Link href="#" underline="none" color='secondary' sx={{ "&:hover": {
                    // background: "#ededed",
                    color: "#EE1723",
                    
                }, m: 1 }}>
                    {<MenuIcon  sx={{
                        fontSize: 'medium', height: "50px", width: "50px", fontWeight: 8,
                        display: {xs: 'block', sm: 'none'}
                        // transform: "rotate(180deg)"
                    }}/>}
                </Link>
                    

            </Toolbar>
               
           
       
        
        
            
        </AppBar>

    </Box>
    </>
   
  )
}

export default Head