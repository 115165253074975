import React from 'react'
import { Typography, Divider, Box } from '@mui/material'

function Meta() {
  return (
    <Box>
        <br /><br />
        <Typography variant="overline" gutterBottom component="div" color="#ccc" textAlign='right'
            sx={{ 
                pr: 2, fontSize: "6px"
            }}>
            &copy; TAE HWA POLAND 2022
        </Typography>
        
    </Box>
  )
}

export default Meta