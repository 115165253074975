import * as React from 'react';
import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Typography, Box, Card, CardHeader, CardContent, Grid, Paper, Container,  CardActions, Button, IconButton, Link, ExpandMore,
List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Badge, ButtonGroup, Divider, Avatar  } from '@mui/material';
import { BrowserRouter,
  Routes,
  Switch,
  Route,
  NavLink,
  
  } from "react-router-dom";
  import CallIcon from '@mui/icons-material/Call';
  import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import pol from '../img/contact/pol.svg';
// import krs from '../img/contact/krs.jpg';
import krs from '../img/contact/krs.svg';
import mal from '../img/contact/mal.jpg';
import fem from '../img/contact/fem.jpg';
import ela from '../img/contact/ela.jpg';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Linking} from 'react';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import CheckIcon from '@mui/icons-material/Check';
import VerifiedIcon from '@mui/icons-material/Verified';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import c1 from '../img/certs/cert1.jpg';
import ta from '../img/home/TAE.svg';
import bm from '../img/home/bmc.svg';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SchoolIcon from '@mui/icons-material/School';

import SelectedBtnContext from '../providers/SelectedBtnProvider';

const stylem = {
  position: 'absolute',
  // top: '5%',
  // left: '5%',
  transform: 'translate(5%, 0%)',
  width: 'auto',
  height: 'auto',
  // bgcolor: 'background.paper',
  // border: '1px solid #000',
  // boxShadow: 8,
  // p: 4,

  // outline: 0,
};


{/* sx={{color: '#EE1723'}} */}
const certs = [
  {
    name: 'ISO 9001:2015',
    img: 'cert1',
    title: 'c01',
    clicked: false,
    start: '09-05-2022',
    expiry: '08-05-2025',
    description: 'Design, production and distribution of plate chains and other metal elements, heat treatment, application of galvanic coatings',
  },
  // {
  //   name: 'ISO 9001:2015',
  //   img: 'cert1',
  //   title: 'c02',
  //   clicked: false,
  //   start: 'n/a',
  //   expiry: '08-05-2025',
  //   description: 'yyyyyyyyyyyyyyyyyyyyyy',
  // },
  // {
  //   name: 'ISO 9001:2015',
  //   img: 'cert1',
  //   title: 'c03',
  //   clicked: false,
  //   start: 'n/a',
  //   expiry: '08-05-2025',
  //   description: 'xxxxxxxxxxxxxxxxxxxxxxxx',
  // },
  // {
  //   name: 'ISO 9001:2015',
  //   img: 'cert1',
  //   title: 'c04',
  //   clicked: false,
  //   start: 'n/a',
  //   expiry: '08-05-2025',
  //   description: 'Design, production and distribution of plate chains and other metal elements, heat treatment, application of galvanic coatings',
  // },
  // {
  //   name: 'ISO 9001:2015',
  //   img: 'cert1',
  //   title: 'c05',
  //   clicked: false,
  //   start: 'n/a',
  //   expiry: '08-05-2025',
  //   description: 'Design, production and distribution of plate chains and other metal elements, heat treatment, application of galvanic coatings',
  // },
  // {
  //   name: 'ISO 9001:2015',
  //   img: 'cert1',
  //   title: 'c06',
  //   clicked: false,
  //   start: 'n/a',
  //   expiry: '08-05-2025',
  //   description: 'Design, production and distribution of plate chains and other metal elements, heat treatment, application of galvanic coatings',
  // },
  
 
];


// const preventDefault = (event) => event.preventDefault(); // bez tego cos Link nie dziala

// const BootstrapTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} arrow classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.arrow}`]: {
//     color: theme.palette.common.black,
//   },
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.black,
//   },
// }));



function Certifications() {

  // const [open, setOpen] = React.useState(false);

// ---------------------------------------------------
  // MOOOOOOOOOOOOOOOOODAL OPEN CLOSE


  const [anchorElx, setAnchorElx] = React.useState(null);
  const [anchorEly, setAnchorEly] = React.useState(null);

  const [currentIndex, setCurrentIndex] = React.useState(undefined);
  const [currentIndexy, setCurrentIndexy] = React.useState(undefined);
  
  const openx = Boolean(anchorElx);
  const openy = Boolean(anchorEly);
  
  const handleOpenx = (index) => (event) => {
    setAnchorElx(event.currentTarget);
    setCurrentIndex(index);
    // console.log(currentIndex);
  };

  const handleClosex = () => {
    setAnchorElx(null);
  };
  const handleClosey = () => {
    setAnchorEly(null);
  };


  //  -----------------------------------------------



  
const handleOpeny = (index) => (event) =>{
  
  
      
      


  
  openy && index === currentIndexy ? 
    

  
      setAnchorEly(undefined) || setCurrentIndexy(undefined)
       
      
      :

      setAnchorEly(event.currentTarget) || setCurrentIndexy(index) 
  

  ;
  certs[index].clicked = !certs[index].clicked; 
 
}





//  -----------------------------------------------
const [color, setColor] = React.useState('secondary');
  
    const onHoverOver = () => {
    //  setVariant('outlined');
     setColor('tae')
    };
    const onHoverOut = () => {
    //  setVariant('text');
     setColor('secondary')
    };
const {selectedBtn, setSelectedBtn } = useContext(SelectedBtnContext);


// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));



  return (
    <div>
      <section id='certs'>

      <Container  >
          <Card sx={{ minWidth: 275, boxShadow:'none',  }}  >
              
                    {/* MAIN CARD----------------- */}
              <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
            
                </Typography>
                <Typography variant="h4" component="div" >
                Certifications

                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Certifications of Achievement of Tae Hwa Poland
                </Typography>
              
              </CardContent>

              <Container>
                {/* <CardActions sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', 
                
                }}>

                  <Button size="small" color="tae" variant="contained" 
                    sx={{color: "white",  mr:'4px', ml:'4px'}}
                    component={NavLink} to='/gallery'
                    onClick={()=>setSelectedBtn(83)} 
                    disabled
                  >
                      Gallery
                  </Button>
                  <Button
                  size="small"
                  color="tae" 
                  variant="outlined"
                  sx={{ mr:'4px', ml:'4px'}}
                  component={NavLink} to='/jobs'
                  onClick={()=>setSelectedBtn(82)} 
                  disabled
                  >
                    JOBS
                  </Button>
                  
                </CardActions> */}
                
              </Container>
              <br />

                   {/* TRIO cards------------- */}
              <Container> 
                <CardContent>
                  <Grid container rowSpacing={{xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                  sx={{ 
                    display: 'flex', flexDirection: 'row', justifyContent: 'center', 
                    
                    }}>
                  
                    {certs.map((item, index) => {
                      const isSelected = openy && index === currentIndexy;
                      
                        return(
                          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} 
                            key={item.title} 
                            sx={{
                              // backgroundColor: 'red',
                            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', 
                            }}
                            >

                      
                          <Card sx={{
                            // boxShadow: '0px 2px 1px -1px rgb(238 23 35 / 20%), 0px 1px 1px 0px rgb(238 23 35 / 14%), 0px 1px 3px 0px rgb(238 23 35 / 12%)',
                            "&:hover": {
                              boxShadow: '0px 2px 1px -1px hsl(120, 100%, 50%, 1), 0px 1px 1px 0px hsl(120, 100%, 50%, 1),  0px 1px 3px 0px hsl(120, 100%, 50%, 1)',
                              "&& button":
                              { color: 'lime' }
                            } 
                            }}>
                            {/* <CardHeader sx={{
                              
                              
                             }}
                              title={item.name}
                              avatar={
                                <Avatar sx={{ bgcolor: 'rgb(238 23 35)' }} >
                                  <WorkspacePremiumIcon sx={{ color: 'white' }} />
                                </Avatar>
                              }
                              
                              subheader={item.description}
                              subheader={"Expiration date: "+item.expiry}
                              
                            /> */}
                            
                            {/* <CardContent 
                            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                            
                            >
                                <Avatar sx={{ bgcolor: 'rgb(238 23 35)', p:2 }} >
                                  <SchoolIcon sx={{ color: 'white' }} />
                                </Avatar>

                                
                                  
                            </CardContent> */}
                            <CardContent sx={{ 
                              minHeight: {xs: '150px', sm: '150px' },
                              // borderBottom: '1px solid rgb(238 23 35)'},
                              borderBottom: '1px solid lime',
                              
                              height: {lg: '180px', xl: '180px', md:'180px'},
                              p:2, textAlign: 'center'}}>
                              <Typography variant="h4"
                                sx={{pb:2, }}
                              
                                >
                                {item.name}
                              </Typography>
                              <Typography variant="body2"
                                  sx={{p:1, }}
                                >
                                {item.description}
                              </Typography>
                                  
                            </CardContent>
    {/* kurrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrwa yeaaaaaaaaaaaaaaaaaaaaaaaaah                         */}
                            <CardContent sx={{
                              // height: {xs: '80px', sm: '80px', md: '80px', lg: '80px', xl: '80px'},
                              outline: 'none',

                              height: item.clicked ? '80px' : '0px',
                              display: 'flex', flexDirection: 'row',  alignItems: 'center',
                              
                              pt: '0px', pb: '0px',
                              // transform: item.clicked ? 'translate(50px,100px)': 'translate(-50px,-100px)',
                              // display: !item.clicked ? 'none' : 'flex',
                              // transition: item.clicked ? 'height 830ms ease' : 'height 830ms ease',
                              // animation: item.clicked ? 'showContent 830ms ease-in-out both' : 'hideContent 830ms ease-in-out both',
                              
                              transition: item.clicked ? 'height 667ms ease' : 'height 667ms ease',
                              animation: item.clicked ? 'showContent 667ms ease-in-out both' : 'hideContent 667ms ease-in-out both',
                          
                              
                              borderBottom: '1px solid lime',
                              backgroundColor: 'gray', 
                              // backgroundColor: '#EE1723', 
                              }}
                                // open={openy && index === currentIndex}
                                // anchorel={anchorEly} 
                                // onClose={handleClosey}
                              
                             >
                              {/* <WorkspacePremiumIcon sx={{ color: 'white', mr:'9px' }} /> */}
                              <Box sx={{ color: 'white', display: 'flex', 
                              flexDirection: 'column', alignItems: 'center', justifyItems: 'center', width: '100%', 
                              // transition: item.clicked ? 'height 320ms ease' : 'height 2s ease',
                              }}>

                                <Box sx={{ display: 'flex', }}>
                                  
                                  <Typography variant="body2"
                                  >
                                    {"Certification Date: "}
                                  </Typography>
                                  <Typography variant="body2" sx={{fontWeight: '800', pl: '10px'}}
                                  >
                                    {item.start}
                                  </Typography>
                                  
                                </Box>
                                <Box sx={{ display: 'flex'}}>
                                  
                                  <Typography variant="body2"
                                  
                                  >
                                    {"Expiry Date: "}
                                  </Typography>
                                  <Typography variant="body2" sx={{fontWeight: '800', pl: '10px'}}
                                  >
                                    {item.expiry}
                                  </Typography>
                                  
                                </Box>
                              </Box>
  
                             
                               {/* sx={{boxShadow: '0px 2px 1px -1px rgb(238 23 35 / 20%), 0px 1px 1px 0px rgb(238 23 35 / 14%), 0px 1px 3px 0px rgb(238 23 35 / 12%)'}} */}
                              <br />
                              
                              
                             
                            </CardContent>
                            
                            <CardActions sx={{display: 'flex', flexDirection: 'row',
                            
                            }}>
                              
                              
                             
                               
                                <IconButton 
                                // color='tae' 
                                  onClick={handleOpeny(index)}
                                
                                  sx={{  }}>
                                  <WorkspacePremiumIcon  
                                    
                                    
                                    sx={{  }} />
                                </IconButton>
                              
                                {/* <ExpandMoreIcon className='hvr'color='secondary' /> */}
                                {/* {
                                  expanded1 ?
                                  <ExpandMoreIcon className='btn'color='secondary' 
                                  sx={{"&:hover": { color: '#EE1723' }}} 
                                  />
                                  :
                                  <ExpandMoreIcon className='btn'color='secondary' 
                                  sx={{"&:hover": { color: '#EE1723' }}} 
                                  />
                                } */}
                              

                              <Box sx={{flexGrow: 1,}}><></></Box>
                              <Button
                              size="small"
                              color={color}
                              // onMouseOver={()=>{onHoverOver()}}
                              // onMouseOut={()=>{onHoverOut()}}
                              variant="text"
                              // sx={{"&:hover": { color: '#EE1723' }}}
                              // disabled
                              onClick={handleOpenx(index)}
                              >
                                VIEW
                              </Button>
{/* ============================================================= modal */}
                                    {/* anchorEl={anchorElB} anchorel bo error wywalało
                                    open={openB && index === currentIndexB} */}
                              <Modal

                                open={openx && index === currentIndex}
                                anchorel={anchorElx} 
                                onClose={handleClosex}

                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"

                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 500,
                                }}

                                sx={{
                                  display: 'flex',
                                  p: 1,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}

                                disableEnforceFocus 
                              >
                                <Box 
                                sx={stylem}
                                >
                                  

                                      <img
                                        src={require('../img/certs/' +
                                        item.img +
                                        '.jpg')} alt='foto' className='cert-img'
                                        onClick={handleClosex}
                                      />
                                </Box>
                              </Modal>
{/* ============================================================= modal */}
                            </CardActions>
                              
                           
  
                          </Card>
                          <br />

                        
                        
                        
                        
                      </Grid>
                      )
                    })}


                
                  </Grid>               
                </CardContent>
              </Container>

    


          </Card>
      </Container>
      <br />                    
      <br />                    
      </section>





    
      

      


















    </div>  

    
  )
}

export default Certifications