import React from 'react'

const TaeLogo = ({fill, stroke}) => {
    return (
        
        <svg
            width="100%" height="100%" fill="#EE1723" stroke="#EE1723" strokeWidth='1'
            viewBox='34 0 136 100'>
                {/* fillx: "#EE1723",
                strokex: "#EE1723",  */}
            {/* <rect width="100%" height="100%" fill="green" stroke='blue' strokeWidth='8'/> */}
            <g>
                <path d="M133.415 129.105C83.9463 121.61 68.3937 89.7551 105.308 71.017 112.803 67.2694 118.424 67.8316 111.491 71.7666 105.308 75.1394 97.4376 84.5084 97.4376 88.4434 97.4376 93.3153 102.31 102.31 105.87 103.621 107.369 104.371 108.68 103.996 108.68 103.059 108.68 101.935 112.241 101.185 116.738 101.185 121.235 101.185 125.357 100.81 125.919 100.248 126.482 99.6862 125.357 92.191 123.483 83.7589 121.61 75.3268 119.923 67.8316 119.923 67.0821 119.923 65.7704 137.162 61.648 138.286 62.7723 138.661 63.1471 139.786 70.0801 140.722 78.1375 143.908 103.621 141.097 101.185 165.831 101.185 178.948 101.185 187.38 101.935 187.38 103.059 187.38 104.183 173.139 104.933 148.592 104.933 113.74 105.12 109.992 105.308 113.365 107.744 127.044 118.237 167.518 118.612 187.567 108.493L194.313 105.12 194.501 88.8181C194.688 79.8239 195.25 72.1413 195.625 71.5792 197.686 69.7054 211.739 80.7608 214.738 86.5696 228.042 112.241 181.946 136.413 133.415 129.105Z" transform="matrix(1 0 0 -1.07993 -48.935 152.853)" />
                <path d="M151.216 94.2522C150.654 93.3153 149.717 85.8201 148.967 77.388L147.655 61.8354 155.151 61.8354 162.833 61.8354 163.395 70.8297C163.958 78.1375 164.52 79.6365 167.705 79.6365 170.703 79.6365 171.64 78.1375 172.015 71.7666 172.577 62.7723 172.39 62.9597 182.321 65.7704L189.441 67.6442 188.879 81.1356 188.317 94.6269 180.447 95.1891C173.139 95.7512 172.577 95.3764 172.015 90.5046 171.265 83.1967 164.145 83.0094 163.395 90.5046 162.833 94.6269 161.896 95.5638 157.399 95.5638 154.401 95.5638 151.59 95.0017 151.216 94.2522Z" transform="matrix(1 0 0 -1.07993 -48.935 152.853)" />
            </g>
        </svg>
        // <svg  width="145" height="83" fill={fill} stroke='white' strokeWidth='1' overflow='hidden'>
            
        //     <g transform="translate(-30 -8)">
        //         <path d="M133.415 129.105C83.9463 121.61 68.3937 89.7551 105.308 71.017 112.803 67.2694 118.424 67.8316 111.491 71.7666 105.308 75.1394 97.4376 84.5084 97.4376 88.4434 97.4376 93.3153 102.31 102.31 105.87 103.621 107.369 104.371 108.68 103.996 108.68 103.059 108.68 101.935 112.241 101.185 116.738 101.185 121.235 101.185 125.357 100.81 125.919 100.248 126.482 99.6862 125.357 92.191 123.483 83.7589 121.61 75.3268 119.923 67.8316 119.923 67.0821 119.923 65.7704 137.162 61.648 138.286 62.7723 138.661 63.1471 139.786 70.0801 140.722 78.1375 143.908 103.621 141.097 101.185 165.831 101.185 178.948 101.185 187.38 101.935 187.38 103.059 187.38 104.183 173.139 104.933 148.592 104.933 113.74 105.12 109.992 105.308 113.365 107.744 127.044 118.237 167.518 118.612 187.567 108.493L194.313 105.12 194.501 88.8181C194.688 79.8239 195.25 72.1413 195.625 71.5792 197.686 69.7054 211.739 80.7608 214.738 86.5696 228.042 112.241 181.946 136.413 133.415 129.105Z" transform="matrix(1 0 0 -1.07993 -48.935 152.853)" />
        //         <path d="M151.216 94.2522C150.654 93.3153 149.717 85.8201 148.967 77.388L147.655 61.8354 155.151 61.8354 162.833 61.8354 163.395 70.8297C163.958 78.1375 164.52 79.6365 167.705 79.6365 170.703 79.6365 171.64 78.1375 172.015 71.7666 172.577 62.7723 172.39 62.9597 182.321 65.7704L189.441 67.6442 188.879 81.1356 188.317 94.6269 180.447 95.1891C173.139 95.7512 172.577 95.3764 172.015 90.5046 171.265 83.1967 164.145 83.0094 163.395 90.5046 162.833 94.6269 161.896 95.5638 157.399 95.5638 154.401 95.5638 151.59 95.0017 151.216 94.2522Z" transform="matrix(1 0 0 -1.07993 -48.935 152.853)" />
        //     </g>
        // </svg>
    );
}

export default TaeLogo;