import * as React from 'react';
import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Typography, Box, Card, CardHeader, CardContent, Grid, Paper, Container, CardMedia, CardActions, Button, IconButton, Link,
List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Badge, ButtonGroup, Divider, Avatar  } from '@mui/material';
import { BrowserRouter,
  Routes,
  Switch,
  Route,
  NavLink,
  
  } from "react-router-dom";
  import CallIcon from '@mui/icons-material/Call';
  import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import pol from '../img/contact/pol.svg';
// import krs from '../img/contact/krs.jpg';
import krs from '../img/contact/krs.svg';
import mal from '../img/contact/mal.jpg';
import fem from '../img/contact/fem.jpg';
import ela from '../img/contact/ela.jpg';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Linking} from 'react';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import CheckIcon from '@mui/icons-material/Check';
import VerifiedIcon from '@mui/icons-material/Verified';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


// import cad01 from '../vectors/chains/cad01.svg';
// import cad02 from '../vectors/chains/cad02.svg';
// import cad03 from '../vectors/chains/cad03.svg';
import ge from '../img/home/geo_small.jpg';
import dz from '../img/home/CAD.svg';
import lg from '../img/home/LG.svg';
import ta from '../img/home/TAE.svg';
import taw from '../img/home/TAEw.svg';


import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SchoolIcon from '@mui/icons-material/School';
import {ChainzContext, ChainzProvider }from '../providers/ChainzProvider';
import SelectedBtnContext from '../providers/SelectedBtnProvider';

import {ThemeContext, themes} from '../providers/ThemeProvider';


import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";



export const SearchBar = ({setSearchQuery}) => (
  
    // <form action=""></form>
      <TextField
        hiddenLabel
        fullWidth
        id="search-bar"
        className="text"
        
        onInput={(e) => {
          setSearchQuery(e.target.value);
          // setCurrentIndexy(e.target.index);
        }}
        // defaultValue="Enter a chain name"
        variant="standard"
        color='secondary'
        // type='search'
        placeholder="Search..."
        size="small"
      />
      
   
);
// const keys = [() => { 
    
//     data.map((key) => data[key].toString().toLowerCase() )
// }];

export const filterDataChainz = (query, data, selectedType) => {
    
    if (!query && selectedType === 'All') {
        return data
      
    } 

    else if (!query && selectedType !== 'All') {
        // return data.filter((d, selectedType)=>

        return data.filter((d)=>

          d.type.toString().startsWith(selectedType) 
      // || d.type.toString().endsWith(selectedType) 
      
      )
      // d.type.toString().includes(selectedType) )
    }
    else if (query && selectedType === 'All') {
      return data.filter((d)=>
          d.name.toString().toLowerCase().includes(query) ||
          d.type.toString().toLowerCase().includes(query) ||
          d.models.find((m) => m.name.toString().toLowerCase().includes(query))
      )
    }
    else if (query && selectedType !== 'All') {
      return data.filter((d)=>
        d.type.toString().startsWith(selectedType) 
        // d.type.toString().includes(selectedType) 
        &&
        (
          d.name.toString().toLowerCase().includes(query) ||
          d.type.toString().toLowerCase().includes(query) ||
          d.models.find((m) => m.name.toString().toLowerCase().includes(query))

        )
      )
    }

    

     
 };


            // d.models.find((m) => m.name.toString().toLowerCase().includes(query) ) ||
            // d.type.valueOf().toString().toLowerCase().includes(query) ||
            // d.models.find((m) => m.spec.toString().toLowerCase().includes(query) ) 





    // {(data.filter((d) => 
    //     d.toString().toLowerCase().includes(query))
    //     .map((item, index) => {
    //         return (
    //             <div key={item.name}>
                                    
    //                 <div className="countryInfo">
    //                     <div>
    //                         <span>{item.name}</span>
    //                         <h3> {item.type} </h3>
    //                     </div>
                        
    //                 </div>
    //             </div>
    //         )
    //     } ) ) }
    
    
        
    
    



// const filterData = (query, data) => {
//     if (!query) {
//         return data;
//     } else {
//         // return data.filter((d) => d.toLowerCase().includes(query));
//         return data.filter((d) => d.toString().toLowerCase().includes(query));  
//     }  
// };

// const data = [
//     "Paris",
//     "London",
//     "New York",
//     "Tokyo",
//     "Berlin",
//     "Buenos Aires",
//     "Cairo",
//     "Canberra",
//     "Rio de Janeiro",
//     "Dublin"
// ];

const style = {
    position: 'absolute',
  
    width: 'auto',
    height: 'auto',
  
  };

function Search() {

    const [chainz, setChainz] = useContext(ChainzContext);
    const [searchQuery, setSearchQuery] = useState("");
    // const dataFiltered = filterData(searchQuery, chainz);

    
    const { themex, setThemex } = useContext(ThemeContext);
  
    const [anchorElx, setAnchorElx] = React.useState(null);
    const [anchorEly, setAnchorEly] = React.useState(null);
    
    const [currentIndex, setCurrentIndex] = React.useState(undefined);
    const [currentIndexy, setCurrentIndexy] = React.useState(undefined);
    
    const openx = Boolean(anchorElx);
    const openy = Boolean(anchorEly);
    
    const handleOpenx = (index) => (event) => {
      setAnchorElx(event.currentTarget);
      setCurrentIndex(index);
      // console.log(currentIndex);
    };
    
    const handleClosex = () => {
      setAnchorElx(null);
    };
    const handleClosey = () => {
      setAnchorEly(null);
    };
  
  
    //  -----------------------------------------------  
    
    
    
    
    //  -----------------------------------------------
    const [haj, setHaj] = React.useState(0);

 
    // const handleOpeny = (index) => (event) =>{
  
      // openy && index === currentIndexy ? 
  
      //   setAnchorEly(undefined) || setCurrentIndexy(undefined)
      //   :
      //   setAnchorEly(event.currentTarget) || setCurrentIndexy(index) 
      // ;
      // chainz[index].clicked = !chainz[index].clicked; 
      
    // }
    // const handleOpeny = (id) => (event) =>{
  
    //   openy && id === currentIndexy ? 
  
    //     setAnchorEly(undefined) || setCurrentIndexy(undefined)
    //     :
    //     setAnchorEly(event.currentTarget) || setCurrentIndexy(id) 
    //   ;
    //   id.clicked = !id.clicked; 
    //   lohi();
    // }
    
    //  -----------------------------------------------
    const [color, setColor] = React.useState('secondary');
    
      const onHoverOver = () => {
      //  setVariant('outlined');
       setColor('tae')
      };
      const onHoverOut = () => {
      //  setVariant('text');
       setColor('secondary')
      };
    const {selectedBtn, setSelectedBtn } = useContext(SelectedBtnContext);


    // return (
        

        

    //     <CardContent>

    //         <Box sx={{
    //             display: 'flex', justifyContent: 'center',
    //             mb: 5, 
    //         }}>

                
    //             {/* <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} /> */}
    //         </Box>













    //             {/* <div style={{ padding: 3 }}>
    //                 {dataFiltered.map((item) => (
    //                 <div
    //                     className="text"
    //                     style={{
    //                     padding: 5,
    //                     justifyContent: "normal",
    //                     fontSize: 20,
    //                     color: "blue",
    //                     margin: 1,
    //                     width: "250px",
    //                     BorderColor: "green",
    //                     borderWidth: "10px"
    //                     }}
    //                     key={item.name}
    //                 >
    //                     {item.name}{item.type}
    //                 </div>
    //                 ))}
    //             </div> */}
            


            
    //       </CardContent>
        
        
        
       





        


        
    // );
  }

export default Search
  

    


