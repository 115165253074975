import React from 'react';
import { Typography, Divider, Link, AppBar, Toolbar, Box } from '@mui/material';
import TaeLogoGraysome from '../vectors/TaeLogoGraysome';
import TaeLogo from '../vectors/TaeLogo';


function Footer() {
  return (
    
    <Box sx={{
        //  display: "flex", flexDirection: 'row',
        // maxHeight: '90px',
    backgroundColor: "rgba(255,255,255,0.28)", 
    // position: "sticky", bottom: 0,
    //  pt: 
    }}>
        
        <br /><Divider />

        <Typography variant="button" gutterBottom component="div" color="#ccc"
            sx={{
                textAlign: 'center', pt: '7px',
                // display: 'flex', alignText: 'center', justifyContent: 'center',
                fontWeight: 400, fontSize: "13px",
                "&:hover": { color: '#000' }, 
                // height: '30px'
            }}>
            TAE HWA POLAND SP. Z O.O.  &sdot; ul. Fabryczna 1, 59-225 Chojnów, Polska &sdot;
            KRS: 0000879279 &sdot; NIP: 6912552637 &sdot; REGON: 387971399 
        </Typography>
        


    </Box>
  )
}

export default Footer