import * as React from 'react';
import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Typography, Box, Card, CardContent, Grid, Paper, Container,  CardActions, Button, IconButton,
List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Badge, ButtonGroup, Divider  } from '@mui/material';
import { BrowserRouter,
  Routes,
  Switch,
  Route,
  NavLink,
  
  } from "react-router-dom";
  import CallIcon from '@mui/icons-material/Call';
  import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import pol from '../img/contact/pol.svg';
// import krs from '../img/contact/krs.jpg';
import krs from '../img/contact/krs.svg';
import mal from '../img/contact/mal.jpg';
import fem from '../img/contact/fem.jpg';
import ela from '../img/contact/ela.jpg';
import team from '../img/contact/peoples.png';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Linking} from 'react'

import SelectedBtnContext from '../providers/SelectedBtnProvider';
import {ThemeContext, themes} from '../providers/ThemeProvider';

const contactBoard = [
  {
    src: 'mal',
    flag: 'krs',
    title: 'bor01',
    name: 'Chang Jin Kim',
    dept: 'Board',
    role: 'CEO',
    tel: '+48694287627',
    email: 'changjin.kim@tae-hwa.com'
  },
  {
    src: 'mal',
    flag: 'krs',
    title: 'bor02',
    name: 'Jeong-Hyun Shin',
    dept: 'Board',
    role: 'Plant Director',
    tel: '+48517113446',
    email: 'jhshin@tae-hwa.eu'
  },
  // {
  //   src: 'mal',
  //   flag: 'krs',
  //   title: 'bor02',
  //   name: 'Hoon Kang',
  //   dept: 'Board',
  //   role: 'Quality Director',
  //   tel: '+48733223844',
  //   email: 'kh@tae-hwa.eu'
  // },
  {
    src: 'mal',
    flag: 'krs',
    title: 'bor03',
    name: 'Hanhiea Ju',
    dept: 'Board',
    role: 'Production Director',
    tel: '+48733440067',
    email: 'juh3389@tae-hwa.eu'
  },
  {
    src: 'mal',
    flag: 'krs',
    title: 'bor04',
    name: 'Sungyup Jung',
    dept: 'Board',
    role: 'CFO',
    tel: '+48534760194',
    email: 'syjung@tae-hwa.eu'
  },
];

const contactCompany = [
  

  {
    src: 'fem',
    flag: 'pol',
    title: 'emp01',
    name: 'Aleksandra Babiarz',
    dept: 'Chains',
    role: 'Sales Specialist',
    tel: '+48570959979',
    email: 'a.babiarz@tae-hwa.eu',
    lan: ['PL', 'EN']
  },
  {
    src: 'fem',
    flag: 'pol',
    title: 'emp02',
    name: 'Monika Kozellek',
    dept: 'Chains',
    role: 'Export Specialist',
    tel: '+48733223301',
    email: 'export@tae-hwa.eu'
  },
  {
    src: 'fem',
    flag: 'pol',
    title: 'emp03',
    name: 'Bogusława Popowicz',
    dept: 'Chains',
    role: 'Sales Specialist',
    tel: '+48733660034',
    email: 'handel@tae-hwa.eu'
  },
  {
    src: 'fem',
    flag: 'pol',
    title: 'emp04',
    name: 'Aneta Biernatowska',
    dept: 'Components',
    role: 'Sales Specialist',
    tel: '+48733441101',
    email: 'aneta.b@tae-hwa.eu'
  },
  {
    src: 'mal',
    flag: 'pol',
    title: 'emp25',
    name: 'Wiesław Kędzia',
    dept: 'Services',
    role: 'Galvanizing Manager',
    tel: '+48733445900',
    email: 'cynkowanie@tae-hwa.eu'
  },
  {
    src: 'fem',
    flag: 'pol',
    title: 'emp36',
    name: 'Joanna Theresiak',
    dept: 'Chains',
    role: 'Production Specialist',
    tel: '+48733444396',
    email: 'asia.t@tae-hwa.eu'
  },
  // {
  //   src: 'fem',
  //   flag: 'pol',
  //   title: 'emp42',
  //   name: 'Dominika Bratko',
  //   dept: 'Chains',
  //   role: 'Quality Specialist',
  //   tel: '',
  //   email: 'dominika.b@tae-hwa.eu'
  // },
  // {
  //   src: 'fem',
  //   flag: 'pol',
  //   title: 'emp43',
  //   name: 'Katarzyna Gachowska',
  //   dept: 'Chains',
  //   role: 'Quality Specialist',
  //   tel: '',
  //   email: 'joanna.m@tae-hwa.eu'
  // },
  // {
  //   src: 'fem',
  //   flag: 'pol',
  //   title: 'emp44',
  //   name: 'Joanna Malinowska',
  //   dept: 'Chains',
  //   role: 'Quality Specialist',
  //   tel: '',
  //   email: 'joanna.m@tae-hwa.eu'
  // },
  {
    src: 'ela',
    flag: 'pol',
    title: 'emp50',
    name: 'Elżbieta Kopcińska',
    dept: 'Components',
    role: 'Quality Engineer',
    tel: '+48792571383',
    email: 'elzbieta.k@tae-hwa.eu'
  },
  // {
  //   src: 'fem',
  //   flag: 'pol',
  //   title: 'emp51',
  //   name: 'Patrycja Kochańska Ciećwiecz',
  //   dept: 'Components',
  //   role: 'Quality Specialist',
  //   tel: '',
  //   email: 'patrycja.kc@tae-hwa.eu'
  // },

  {
    src: 'fem',
    flag: 'pol',
    title: 'emp61',
    name: 'Alicja Płochocka',
    dept: 'Logistics',
    role: 'Purchasing Specialist',
    tel: '+48733402422',
    email: 'zaopatrzenie@tae-hwa.eu'
  },
  {
    src: 'fem',
    flag: 'pol',
    title: 'emp72',
    name: 'Agnieszka Tucholska',
    dept: 'Continuous Improvements',
    role: 'Innovations Specialist',
    tel: '+48733446693',
    email: 'agnieszka.t@tae-hwa.eu'
  },
  {
    src: 'mal',
    flag: 'pol',
    title: 'emp83',
    name: 'Tomasz Drazny',
    dept: 'Facility',
    role: 'Maintenance Manager',
    tel: '+48733909969',
    email: 'tomasz.n@tae-hwa.eu'
  },
  {
    src: 'fem',
    flag: 'pol',
    title: 'emp94',
    name: 'Izabella Papież',
    dept: 'Accounting & Administration',
    role: 'Settlements Specialist',
    tel: '+48733233585',
    email: 'rozliczenia@tae-hwa.eu'
  },
  // {
  //   src: 'mal',
  //   flag: 'pol',
  //   title: 'emp95',
  //   name: 'Tomasz Nowak',
  //   dept: 'Accounting & Administration',
  //   role: 'Administration Specialist',
  //   tel: '',
  //   email: 'tomasz.n@tae-hwa.eu'
  // },
  
  {
    src: 'fem',
    flag: 'pol',
    title: 'emp96',
    name: 'Aneta Kłodnicka',
    dept: 'Accounting & Administration',
    role: 'HR Specialist',
    tel: '+48534256945',
    email: 'kadry@tae-hwa.eu'
  },


] ;


// const [btnClass, setBtnClass] = useState(null);
// const [btnColor, setBtnColor] = useState(null);

function Contacts() {
  const { themex, setThemex } = useContext(ThemeContext);
  
  const greeni = themex.greenisz;
  const blui = themex.bluisz;

  
  // const [menu, setMenu] = React.useState(null);
  // const open = Boolean(menu);
  // const handleMenuOpen = (event)=> {
  //   setMenu(event.currentTarget)
  // };
  // const handleMenuClose = ()=> {
  //   setMenu(null);
  // };


  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const handleClick = (index) => (event) => {
    setAnchorEl(event.currentTarget);
    setCurrentIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const [anchorElB, setAnchorElB] = React.useState(null);
  const [currentIndexB, setCurrentIndexB] = React.useState(0);
  const openB = Boolean(anchorElB);
  const handleClickB = (index) => (event) => {
    setAnchorElB(event.currentTarget);
    setCurrentIndexB(index); 
  };
  const handleCloseB = () => {
    setAnchorElB(null);
  };


  // maps goooooooooooooooooooogle
  const lat = 51.28025336250981;
  const lng = 15.937625195813265;
  const showInMapClicked = () => {
    window.open(
      `https://maps.google.com/maps/place/Tae+Hwa+Poland+Sp.+z+o.o./@${lat},${lng},18.25z/data=!4m8!1m2!3m1!2sTae+Hwa+Poland+Sp.+z+o.o.!3m4!1s0x470f41f17a333a61:0x88221f2ed44fde2e!8m2!3d51.2802468!4d15.9375764`
      );
    };
// feedback
    const feedbackKontakt = ()=> {
      window.location = 'mailto:kontakt@tae-hwa.eu'
   }

   const [color, setColor] = React.useState('secondary');
  
   const onHoverOver = () => {
   //  setVariant('outlined');
    setColor('tae')
   };
   const onHoverOut = () => {
   //  setVariant('text');
    setColor('secondary')
   };
   const {selectedBtn, setSelectedBtn } = useContext(SelectedBtnContext);

  return (
    <div>
 

      <section>
         {/* {
                    contactData.map((item) => { const isDept = item.dept === 'Board';
                     return (
                       { item.dept === 'Board' ? */}
                       {/* {  isDept &&  */}
         
        <Container  >

        {/* sx={{color: '#EE1723'}} */}
          <Card sx={{ minWidth: 275, boxShadow: 'none' }}  >
              <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
            
                </Typography>
                <Typography variant="h4" component="div" >
                Company Contacts

                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {/* Tea Hwa Poland is maintained by a group of invaluable core employees, with the massive support and involvement of the Board Members */}
                Office hours: Mon - Fri  &sdot; 7am - 3pm CET
                </Typography>
              
              </CardContent>
              {/* <Container> */}
                {/* <CardActions sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', 
                // justifyContent: 'space-between' 
              }}> */}

                  {/* <Button
                    size="small"
                    color='tae'
                    variant="outlined"
                    sx={{ mr:'4px', ml:'4px'}}
                    // component='a'
                    // key="email"
                    // sx={{ m:1}}
                    // href={"mailto:kontakt@tae-hwa.eu"}
                    onClick={feedbackKontakt}
                      
                    >
                    Feedback
                  </Button> */}

                  
                  {/* <Button 
                    size="small"  variant="contained" 
                    // color={greeni}
                    color='tae'
                    sx={{
                    color: 'white',  mr:'4px', ml:'4px'
                    // backgroundcolor: '#52BE80',
                    // "&:hover": { color: '#52BE80' }, 
                  }}
                    component={NavLink} to='/about'
                    onClick={()=>setSelectedBtn(0)} 
                    // disabled
                  >
                      Company
                  </Button> */}

                  
                {/* </CardActions> */}
                
              {/* </Container> */}
              <br />
              {/* <Container>
                
                <CardContent sx={{display: 'flex', flexDirection:'column',alignItems: 'center', p:8, }}>

                  <Box>
                  <img
                    src={team} alt='foto' className='home-team-img'
                  />
                  </Box>

                  <Typography sx={{ mb: 1.5, textAlign: 'center' }} color="text.secondary">
                      Office hours: Mon - Fri  &sdot; 7am - 3pm CET
                  
                    </Typography>  
                    
                </CardContent>

              </Container> */}



              <Container>

              <Grid container rowSpacing={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 3 }}>
                  
                  
                              
             
                {contactCompany.map((item, index) => {
                   const isPhone = item.tel !== '';
                   
                   
                  //  const lanArr = item.lan;
                  //  const lanItem = lanArr.map((i, index) =>
                  //           <li key={index}>
                  //             {i}
                  //           </li>
                  //         );
                  
                return (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={4} 
                // sx={{display: 'flex', flexDirection:'column' }}
                key={item.title}
                >


                  
                      <Card
                      //  key={item.title}
                       >     

                          <CardContent sx={{height: {xs: '165px', sm: '165px', md: '165px', lg: '165px', xl: '165px'}, }} >
                            <Box sx={{display: 'flex', flexDirection: 'column',  justifyContent: 'center', textAlign: 'center'}}>
                              <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                              
                                
                              <Badge 
                              sx={{
                                color: 'transparent'
                              }}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              overlap="circular"
                              badgeContent={
                                <img 
                                  src={require('../img/contact/' +
                                  item.flag +
                                  '.svg')} alt='foto' className='contact-flag'
                                />

                              } 
                              >
                               
                                <img
                                  src={require('../img/contact/' +
                                  item.src +
                                  '.jpg')} alt='foto' className='contact-img'/>
                              </Badge>
                                
                                {/* <img
                                  src={require('../img/contact/' +
                                  item.src +
                                  '.jpg')} alt='foto' className='contact-img'/> */}
                              

                              </Box> 
                                    <br />
                              <Box sx={{display: 'flex', flexDirection: 'column', alignContent: 'center', justifiyContent: 'center'}}>

                                
                                <Typography variant='button' sx={{borderBottom: '1px solid lightgrey'}}>
                                  
                                {item.name}
                                </Typography>
                                
                                <Typography variant='body' sx={{
                                  // fontWeight: 'bold',
                                   color: 'darkgrey'}}>
                                  
                                {item.role}
                                </Typography>
                                <Typography variant='caption' >
                                  
                                {item.dept}
                                </Typography>
                                


                              </Box>
                              <hr />

                            </Box>
                            
                            <br />
                            
                          
                          
                          </CardContent>
                          <CardActions sx={{backgroundColor: '#fafafa', display: 'flex'}}>

                              <IconButton id='mail-me' href={"mailto:" + item.email} 
                                sx={{

                                  "&:hover": { color: '#5DADE2' }, 
                                }}
                                size='large'
                                >
                                <MailIcon />
                              </IconButton>
                              <Box sx={{flexGrow: 1}}></Box>
        {/* =========================================================                     */}
                              {isPhone && 

                                <div>
                                  <IconButton 
                                    sx={{

                                      "&:hover": { color: '#52BE80' }, 
                                    }}
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick(index)}
                                    size='large'
                                  >
                                    <CallIcon />
                                  </IconButton>
                                  <Menu
                                  // dense
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open && index === currentIndex}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      'aria-labelledby': 'basic-button',
                                    }} sx={{textAlign: 'center',}}
                                  >
                                    <Typography variant='button' sx={{
                                      
                                      pl:1, pr:1, pb:1}}>
                                  
                                        {item.name}
                                    </Typography>
                                    <Divider />
                                    
                                        <Button href={"tel:" + item.tel} color='success' variant="contained" onClick={handleClose} sx={{
                                          color: 'white',
                                          ml:1, mt:1, mr:1
                                          }}> 
                                            {/* <IconButton>
                                              <CallIcon />
                                            </IconButton> */}
                                            CALL ME
                                        </Button>
                                        <Button color='success' variant="outlined" onClick={()=>{ navigator.clipboard.writeText(item.tel); handleClose()}} sx={{
                                          // color: 'white'
                                          ml:1, mt:1, mr:1
                                          }}> 
                                            {/* <IconButton>
                                              <ContentCopyIcon />
                                            </IconButton> */}
                                            COPY NO.
                                        </Button>
                                      
                                    
                                    {/* </MenuItem> */}
                                   


                                   
                                    
                                  </Menu>
                                </div>
                                }

                                {/* {
                                  item.lan.map((el, i) => {
                                    <List key={el.i}>
                                    return (

                                  <ListItem sx={{p:1, mr:1, pr:2 }} >
                                {el}

                                 </ListItem>

                                    )
                                    </List>

                                  }
                                  

                                  
                                  )
                                } */}
                                

                               
                                {/* <Typography variant='caption' sx={{
                                  
                                  color: 'darkgrey'}}>
                                    <List>
                                  <ListItem sx={{p:1, mr:1, pr:2 }}>
                                {item.lan}

                                 </ListItem>

                                    </List>

                                 
                                </Typography> */}

                              

                                  

                            
 
                            
                          
                          </CardActions>
                        



                        
                      
                      </Card> 
                      
                      </Grid> 
                      );})} 
                       
                      <br />
                      
                      
                  
                  
                
                </Grid>
              </Container>

{/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
              <Container>
                
                <CardContent sx={{display: 'flex', flexDirection:'column',alignItems: 'center', p:8, }}>

                  <Box>
                  <img
                    src={team} alt='foto' className='home-team-img'
                  />
                  </Box>

                  {/* <Typography sx={{ mb: 1.5, textAlign: 'center' }} color="text.secondary">
                      Office hours: Mon - Fri  &sdot; 7am - 3pm CET
                  
                    </Typography>   */}
                    
                </CardContent>

              </Container>
                {/* <CardContent> */}
             
{/*                 
                <br />
                <br /> */}
                {/* <hr /> */}
                
                {/* <Typography sx={{ mb: 1.5, textAlign: 'center' }} color="text.secondary">
                   Preferable languages of contact: Polish, Korean, German, English
              
                </Typography>   */}
                
                

                {/* <iframe width="100%" height="100%"  frameBorder="0" title="map" marginHeight="0" marginWidth="0" scrolling="no" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=%C4%B0ikaneir+(Mumma's%20Bakery)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
                 style={{filter: 'scale(100) contrast(1.2) opacity(0.4)'}}></iframe> */}


                {/* <br />
                </CardContent> */}

              {/* ================================================================================================================================================================ */}
              

            <Container>

              <Grid container 
                rowSpacing={{ xs: 3, sm: 3, md: 3, lg: 3, xl: 3 }}
                columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 3 }}>
                  
                {contactBoard.map((item, index) => {
                   const isPhone = item.tel !== '';
                  
                    return (
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={4} 
                         key={item.title} 
                         >
                          <Card>     

                          <CardContent sx={{height: {xs: '165px', sm: '165px', md: '165px', lg: '165px', xl: '165px'}, }} >
                            <Box sx={{display: 'flex', flexDirection: 'column',  justifyContent: 'center', textAlign: 'center'}}>
                              <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                              
                                

                              <Badge 
                              sx={{
                                color: 'transparent'
                              }}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              overlap="circular"
                              badgeContent={
                                <img 
                                  src={require('../img/contact/' +
                                  item.flag +
                                  '.svg')} alt='foto' className='contact-flag'
                                />

                              } 
                              >
                               
                                <img
                                  src={require('../img/contact/' +
                                  item.src +
                                  '.jpg')} alt='foto' className='contact-img'/>
                              </Badge>
                              

                              </Box> 
                                    <br />
                              <Box sx={{display: 'flex', flexDirection: 'column', alignContent: 'center', justifiyContent: 'center'}}>

                                
                                <Typography variant='button' sx={{borderBottom: '1px solid lightgrey'}}>
                                  
                                {item.name}
                                </Typography>
                                
                                <Typography variant='body' sx={{
                                
                                     color: 'darkgrey',
                                }}>
                                  
                                {item.role}
                                </Typography>
                                <Typography variant='caption' >
                                  
                                {item.dept}
                                </Typography>
                                


                              </Box>
                              <hr />

                            </Box>
                            
                            <br />
                            
                          
                          
                          </CardContent>

                          <CardActions sx={{backgroundColor: '#fafafa', display: 'flex'}}>

                              <IconButton id='mail-me' href={"mailto:" + item.email} 
                                sx={{

                                  "&:hover": { color: '#5DADE2' }, 
                                }}
                                size='large'
                                >
                                <MailIcon />
                              </IconButton>
                              <Box sx={{flexGrow: 1}}></Box>
 
                              {isPhone && 

                                <div>
                                  <IconButton 
                                    sx={{

                                      "&:hover": { color: '#52BE80' }, 
                                    }}
                                    id="basic-button"
                                    aria-controls={openB ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openB ? 'true' : undefined}
                                    onClick={handleClickB(index)}
                                    size='large'
                                  >
                                    <CallIcon />
                                  </IconButton>
                                  <Menu
                               
                                    id="basic-menu"
                                    anchorEl={anchorElB}
                                    open={openB && index === currentIndexB}
                                    onClose={handleCloseB}
                                    MenuListProps={{
                                      'aria-labelledby': 'basic-button',
                                    }} sx={{textAlign: 'center',}}
                                  >
                                    <Typography variant='button' sx={{
                                      
                                      pl:1, pr:1, pb:1}}>
                                  
                                        {item.name}
                                    </Typography>
                                    <Divider />
                                    
                                        <Button href={"tel:" + item.tel} color='success' variant="contained" onClick={handleCloseB} sx={{
                                          color: 'white', 
                                          ml:1, mt:1, mr:1
                                          }}> 
                                           
                                            CALL ME
                                        </Button>
                                        <Button color='success' variant="outlined" onClick={()=>{ navigator.clipboard.writeText(item.tel); handleCloseB()}} sx={{
                                       
                                          ml:1, mt:1, mr:1
                                          }}> 
                                           
                                            COPY NO.
                                        </Button>

                                  </Menu>
                                </div>
                              }
                                

                            
 
                            
                          
                          </CardActions>
                                         
                      
                      </Card> 
                      <br />
                      </Grid> 
                )})} 
                       
                
                      
                      
                  
                  
                
              </Grid>
            </Container>

                
            
        


          </Card>     
        </Container>                    

      </section>
                      
      <br /><br /><br />

    </div>

    
  );
}

export default Contacts