import * as React from 'react';
import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Typography, Box, Card, CardHeader, CardContent, Grid, Paper, Container,  CardActions, Button, IconButton, Link,
List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Badge, ButtonGroup, Divider  } from '@mui/material';
import { BrowserRouter,
  Routes,
  Switch,
  Route,
  NavLink,
  
  } from "react-router-dom";
  import CallIcon from '@mui/icons-material/Call';
  import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import pol from '../img/contact/pol.svg';
// import krs from '../img/contact/krs.jpg';
import krs from '../img/contact/krs.svg';
import mal from '../img/contact/mal.jpg';
import fem from '../img/contact/fem.jpg';
import ela from '../img/contact/ela.jpg';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Linking} from 'react';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import CheckIcon from '@mui/icons-material/Check';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedOutlinedIcon from '@mui/icons-material/Verified';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import SelectedBtnContext from '../providers/SelectedBtnProvider';
// import {ThemeContext, themes} from '../providers/ThemeProvider';

import lg from '../img/home/LG.svg';
import ta from '../img/home/TAE.svg';
import bm from '../img/home/bmc.svg';

{/* sx={{color: '#EE1723'}} */}
const partners = [
  {
    name: 'TAE HWA ENTERPRISE',
    img: 'TAE',
    title: 'p01',
    address: 'http://tae-hwa.com/',
  },
  {
    name: 'LG ELECTRONICS',
    img: 'LG',
    title: 'p02',
    address: 'https://www.lg.com/pl',
  },
  {
    name: 'BMC POLAND',
    img: 'bmc',
    title: 'p03',
    address: 'https://bmcpoland.pl/',
  },
  
  // {
  //   name: 'TAE HWA ENTERPRISE',
  //   img: 'TAE',
  //   title: 'p04',
  //   address: 'http://tae-hwa.com/',
  // },
  // {
  //   name: 'LG ELECTRONICS',
  //   img: 'LG',
  //   title: 'p05',
  //   address: 'https://www.lg.com/pl',
  // },
  // {
  //   name: 'BMC POLAND',
  //   img: 'bmc',
  //   title: 'p06',
  //   address: 'https://bmcpoland.pl/',
  // },
  
  // {
  //   name: 'TAE HWA ENTERPRISE',
  //   img: 'TAE',
  //   title: 'p07',
  //   address: 'http://tae-hwa.com/',
  // },
  // {
  //   name: 'LG ELECTRONICS',
  //   img: 'LG',
  //   title: 'p08',
  //   address: 'https://www.lg.com/pl',
  // },
  // {
  //   name: 'BMC POLAND',
  //   img: 'bmc',
  //   title: 'p09',
  //   address: 'https://bmcpoland.pl/',
  // },
  // {
  //   name: 'TAE HWA ENTERPRISE',
  //   img: 'TAE',
  //   title: 'p10',
  //   address: 'http://tae-hwa.com/',
  // },
  // {
  //   name: 'LG ELECTRONICS',
  //   img: 'LG',
  //   title: 'p11',
  //   address: 'https://www.lg.com/pl',
  // },
  // {
  //   name: 'BMC POLAND',
  //   img: 'bmc',
  //   title: 'p12',
  //   address: 'https://bmcpoland.pl/',
  // },
];

const feedbackKontakt = ()=> {
  window.location = 'mailto:kontakt@tae-hwa.eu'
}
const preventDefault = (event) => event.preventDefault(); // bez tego cos Link nie dziala

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));



function About() {
  // const { themex, setThemex } = useContext(ThemeContext);
  const lat = 51.28025336250981;
  const lng = 15.937625195813265;
  const showInMapClicked = () => {
    window.open(
      `https://maps.google.com/maps/place/Tae+Hwa+Poland+Sp.+z+o.o./@${lat},${lng},18.25z/data=!4m8!1m2!3m1!2sTae+Hwa+Poland+Sp.+z+o.o.!3m4!1s0x470f41f17a333a61:0x88221f2ed44fde2e!8m2!3d51.2802468!4d15.9375764`
      );
    }; 
    
    const [color, setColor] = React.useState('secondary');
  
    const onHoverOver = () => {
    //  setVariant('outlined');
     setColor('tae')
    };
    const onHoverOut = () => {
    //  setVariant('text');
     setColor('secondary')
    };
    const {selectedBtn, setSelectedBtn } = useContext(SelectedBtnContext);

  return (
    <div>
      <section id='about-past'>

      <Container  >
          <Card sx={{ minWidth: 275, boxShadow:'none' }}  >
              
                    {/* MAIN CARD----------------- */}
              <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
            
                </Typography>
                <Typography variant="h4" component="div" >
                About Company

                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Area of activity: Production & Services related to metalworking
                {/* Tae Hwa Poland is on the mission is to sustainably and comprehensively develop as dealing with metalworking daily */}
                </Typography>
              
              </CardContent>

              <Container>
                <CardActions sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', 
                
                }}>
                  
                  
                  {/* <Box sx={{flexGrow: 1,}}><></></Box> */}
                  
                  {/* <Button 
                    size="small" 
                    color="tae" 
                    variant="outlined" 
                    sx={{ mr:'4px', ml:'4px'}}
                    onClick={showInMapClicked}>
                     Location
                     
                  </Button> */}
                  {/* <Button 
                    size="small" 
                    color='tae'
                    variant="contained" 
                    sx={{ color: 'white', mr:'4px', ml:'4px'}}
                   
                    component={NavLink} to='/contacts'
                    onClick={()=>setSelectedBtn(1)} >
                    Contact
                  </Button> */}


                </CardActions>
                
              </Container>

              <br />


                                    {/* CORE DATA-------------- */}
              <Container>
                
                <CardContent>

                    
                  <Grid container 
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                  
                  
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        
                        
                        <Card
                          sx={{boxShadow: 'none'}}
                          >

                          
                          <CardContent
                           sx={{
                            
                            minHeight: {xs: '400px', sm: '400px', md: '400px', lg: '400px', xl: '400px'},
                            
                            }} >
                            
                            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center',}}>

                              <Typography sx={{ mb: 1.5, textAlign: 'center',  }}  variant='h2'>
                                TAE HWA POLAND
                            
                              </Typography>  
                              <Typography sx={{ mb: 1.5, textAlign: 'center', }} color="text.secondary" variant='caption'>
                                SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ
                            
                              </Typography>  
                            </Box>


                            

                            <Box sx={{ 
                              display: 'flex', flexDirection: 'row', justifyContent: 'center', }} 
                              >
                              <Box sx={{ 
                              display: 'flex', flexDirection: 'column', justifyContent: 'center', }} >
                                <Typography sx={{ mb: .5, textAlign: 'start', pr:1 }}  variant='h6'>
                                  KRS
                                </Typography>
                                <Typography sx={{ mb: .5, textAlign: 'start', pr:1 }}  variant='h6'>
                                  NIP
                                </Typography> 
                                <Typography sx={{ mb: .5, textAlign: 'start', pr:1 }}  variant='h6'>
                                  REGON
                                </Typography> 
                              </Box>
                              <Box sx={{ 
                              display: 'flex', flexDirection: 'column', justifyContent: 'center', }} >
                                <Typography sx={{ mb: .5, textAlign: 'end',  }} color="text.secondary" variant='h6'>
                                  0000879279
                                </Typography>
                                <Typography sx={{ mb: .5, textAlign: 'end', }} color="text.secondary" variant='h6'>
                                  6912552637
                                </Typography> 
                                <Typography sx={{ mb: .5, textAlign: 'end',  }} color="text.secondary" variant='h6'>
                                  387971399
                                </Typography> 
                              </Box>
                                  
                                
                                 
                                
                            </Box>
                            
                            


                            <Box sx={{ 
                              display: 'flex', flexDirection: 'column', justifyContent: 'center', }} 
                              >
                                <Typography sx={{ mb: .5, textAlign: 'center', pt:5 }}  variant='caption'>
                                  Address
                                </Typography>  
                                
                                
                                <Typography sx={{ mb: .5, textAlign: 'center', }} color="text.secondary" variant='caption'>
                                  ul. Fabryczna 1,
                                
                                  59-225 Chojnów,
                                  woj. dolnośląskie,
                                
                                  Polska
                                </Typography> 
                                
                                
                            </Box>
                        
                            
                          
                          </CardContent>

                        </Card>
                        
                        
                        
                    </Grid>

                  </Grid>               
                </CardContent>
              </Container>  
                            
                   {/* TRIO------------- */}
              <Container>
                
                <CardContent>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  
                  
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>

                        
                        
                        <Card sx={{
                          "&:hover": {
                            boxShadow: '0px 2px 1px -1px rgb(238 23 35 / 20%), 0px 1px 1px 0px rgb(238 23 35 / 14%), 0px 1px 3px 0px rgb(238 23 35 / 12%)',
                            "&& .hvr":
                            { color: '#EE1723' }
                          } 
                        }}>
                          <CardHeader
                            title="BACKGROUND"
                            subheader="Foundations &amp; History"
                            
                          />
                          <br />
                          <CardContent sx={{
                            minHeight: {xs: '300px', sm: '300px', md: '300px', },
                            height: { lg: '650px', xl: '650px'} 
                            }} >
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>
                              <TurnedInIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></TurnedInIcon> 
                              
                              <Typography variant='subtitle2' >
                              The Company was established on Jan 2021 in association and with initiative of TAE HWA ENTERPRISE form South Korea
                              </Typography>
                              
                            </Box>

                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <TurnedInIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></TurnedInIcon> 
                              <Typography variant='subtitle2' >
                              Tae Hwa Poland is based in former factory of the legendary "Agromet-Dolzamet" Company founded in 1946 in Chojnów, Poland
                              </Typography>
                            </Box>
                            
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <TurnedInIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></TurnedInIcon> 
                              <Typography variant='subtitle2' >
                              The Company took over a part of business area that since 2017 had belonged to BMC POLAND, whom it currently shares the production facility with
                              </Typography>
                            </Box>
                            
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <TurnedInIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></TurnedInIcon> 
                              <Typography variant='subtitle2' >
                              BMC POLAND itself had been a continuator of manufacturing of chains and galvanizing services decades lasting legacy
                              </Typography>
                            </Box>

                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <TurnedInIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></TurnedInIcon> 
                              <Typography variant='subtitle2' >
                              Tae Hwa Poland became a direct successor of BMC POLAND through inheritance of particular business area along with facility, machine park, technology, database, "know-how", and experienced personnel
                              </Typography>
                            </Box>
                            
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <TurnedInIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></TurnedInIcon> 
                              <Typography variant='subtitle2' >
                              Tea Hwa Poland became a rightful owner of CAD DOLZAMET - a trademark of chains, that emareged in early 1990s - and was commonly renowned due to its durability, reliability, high-quality and vast range of products
                              </Typography>
                            </Box>
                            
                            <br />
                            
                            
                           
                          </CardContent>
                          
                          <CardActions sx={{display: 'flex', flexDirection: 'row', 
                          
                          }}>
                            <Box sx={{flexGrow: 1,}}><></></Box>
                            <Button
                            size="small"
                            // color="secondary" 
                            variant="text"
                            component={NavLink} to='/certifications'
                            className='hvr'
                            color={color}
                            onMouseOver={()=>{onHoverOver()}}
                            onMouseOut={()=>{onHoverOut()}}
                            // sx={{"&:hover": { color: '#EE1723' }}}
                            // disabled
                            >
                              Certifications
                            </Button>

                            
                          </CardActions>
                            
                         

                        </Card>
                        <br />
                        
                        
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        
                        <Card sx={{
                          "&:hover": {
                            boxShadow: '0px 2px 1px -1px rgb(238 23 35 / 20%), 0px 1px 1px 0px rgb(238 23 35 / 14%), 0px 1px 3px 0px rgb(238 23 35 / 12%)',
                            "&& .hvr":
                            { color: '#EE1723' }
                          } 
                        }}>
                        <CardHeader
                            title="PROFILE"
                            subheader="Business Area"
                            
                          />
                          <br />
                          <CardContent sx={{
                            minHeight: {xs: '300px', sm: '300px', md: '300px', },
                            height: { lg: '650px', xl: '650px'} 
                             }} >
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <VerifiedIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></VerifiedIcon> 
                              <Typography variant='subtitle2' >
                              Manufacturing and worldwide distribution of chains is now one of the key sectors of the Company's activity
                              </Typography>
                            </Box>
                            
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <VerifiedIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></VerifiedIcon> 
                              <Typography variant='subtitle2' >
                              Manufacturing of Home Appliance Components for a strategic client - LG ELECTRONICS, is being the other
                              </Typography>
                            </Box>
                            
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <VerifiedIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></VerifiedIcon> 
                              <Typography variant='subtitle2' >
                              For regular customers Tea Hwa Poland keeps providing the versatile of services of scope of metalworking - such as Galvanizing, Heat Treatment and CNC Laser Cutting
                              </Typography>
                            </Box>
                            
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <VerifiedIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></VerifiedIcon> 
                              <Typography variant='subtitle2' >
                              The company core is a solid combination of youthful enthusiasm and experienced staff - not uncommonly of a practice exceeding 30 years
                              </Typography>
                            </Box>
                            
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <VerifiedIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></VerifiedIcon> 
                              <Typography variant='subtitle2' >
                              Specialists are contributing across all departments, with main focus on Production, Quality, Maintenance, Administration, Purchase and Sales
                              </Typography>
                            </Box>
                                                    
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <VerifiedIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></VerifiedIcon> 
                              <Typography variant='subtitle2' >
                              Due to the business area take over from BMC POLAND, Tae Hwa Poland assures, that still the same professionals keep meeting clients requirements either based on specifications, drawings and samples as before
                              </Typography>
                            </Box>
                            
                            <br />
                            
                            
                            
                          </CardContent>
                          
                          <CardActions sx={{display: 'flex', flexDirection: 'row', 
                          
                          }}>
                            <Box sx={{flexGrow: 1,}}><></></Box>
                            {/* <Button
                            size="small"
                            // color="secondary" 
                            variant="text"
                            className='hvr'
                            color={color}
                            onMouseOver={()=>{onHoverOver()}}
                            onMouseOut={()=>{onHoverOut()}}
                            // sx={{"&:hover": { color: '#EE1723' }}}
                            disabled
                            >
                              Photo-Gallery
                            </Button> */}
                            <Button 
                              size="small" 
                              // color="tae" 
                              variant="text" 
                              // sx={{ mr:'4px', ml:'4px'}}
                              onClick={showInMapClicked}
                              className='hvr'
                              color={color}
                              onMouseOver={()=>{onHoverOver()}}
                              onMouseOut={()=>{onHoverOut()}}>
                              Location
                              
                            </Button>
                          </CardActions>
                          
                          

                        </Card>
                        <br />
                        
                        
                        
                    </Grid>


                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        
                      <Card sx={{
                        "&:hover": {
                          boxShadow: '0px 2px 1px -1px rgb(238 23 35 / 20%), 0px 1px 1px 0px rgb(238 23 35 / 14%), 0px 1px 3px 0px rgb(238 23 35 / 12%)',
                          "&& .hvr":
                          { color: '#EE1723' }
                        } 
                      }}>
                        
                        <CardHeader
                            title="GOAL"
                            subheader="Values &amp; Pursuit"
                            
                        />
                        <br /> 
                          <CardContent sx={{
                            minHeight: {xs: '300px', sm: '300px', md: '300px',},
                            height: { lg: '650px', xl: '650px'} 
                            }} >
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <TurnedInNotIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></TurnedInNotIcon> 
                              <Typography variant='subtitle2' >
                              The Company is aiming high goals trying to always deliver the best quality of products, components and services for our customers
                              </Typography>
                            </Box>
                            
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <TurnedInNotIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></TurnedInNotIcon> 
                              <Typography variant='subtitle2' >
                              With the takeover of the business area from BMC POLAND, Tae Hwa Poland along with the parent company entered with high hopes into a new sector of industry
                              </Typography>
                            </Box>
                            
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <TurnedInNotIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></TurnedInNotIcon> 
                              <Typography variant='subtitle2' >
                              Since then, with a good deal of organizational agility and huge team effort we gradually improved in many business areas - aiming to support our clients in a faster and better way
                              </Typography>
                            </Box>
                            
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <TurnedInNotIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></TurnedInNotIcon> 
                              <Typography variant='subtitle2' >
                              At the Company we share some common values that we believe can bring us closer to our goal - we are determined, customer oriented, dedicated to detail, open to new ideas and challanges, hard working professionals
                              </Typography>
                            </Box>
                            
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <TurnedInNotIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></TurnedInNotIcon> 
                              <Typography variant='subtitle2' >
                              At Tae Hwa Poland we highly value the respect for others regardless of race, nationality, gender, age or social status
                              </Typography>
                            </Box>
                            
                            <Box sx={{display: 'flex', flexDirection: 'row', pb: 1, }}>                            
                              <TurnedInNotIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></TurnedInNotIcon> 
                              <Typography variant='subtitle2' >
                              The highest attention is put to the health and safety as well as environmental and legal issues, which are frequent subject of consultations with third parties
                              </Typography>
                            </Box>
                            
                            <br />
                           
                            
                            
                          </CardContent>
                          <Container>
                          <CardActions sx={{display: 'flex', flexDirection: 'row', 
                          
                          }}>
                            <Box sx={{flexGrow: 1,}}><></></Box>
                            {/* <Button size="small" 
                              color="secondary"
                              variant="text" 
                              className='hvr'
                              // color={color}
                              onMouseOver={()=>{onHoverOver()}}
                              onMouseOut={()=>{onHoverOut()}}
                              // sx={{"&:hover": { color: '#EE1723' }}}
                              disabled
                              >
                              Job opportunities
                            </Button> */}
                            <Button
                              size="small"
                             
                              variant="text"
                              sx={{ mr:'4px', ml:'4px'}}
                              className='hvr'
                              // color={color}
                              onMouseOver={()=>{onHoverOver()}}
                              onMouseOut={()=>{onHoverOut()}}
                              // sx={{"&:hover": { color: '#EE1723' }}}
                              // component='a'
                              // key="email"
                              // sx={{ m:1}}
                              // href={"mailto:kontakt@tae-hwa.eu"}
                              onClick={feedbackKontakt}
                                
                              >
                              Feedback
                            </Button>
                          </CardActions>
                            
                          </Container>

                      </Card>
                        
                      
                      
                    </Grid>
                  
                
                  </Grid>               
                </CardContent>
              </Container>

              <br />        

                    {/* LOGO LINKS PARTNERS------------        */}
              <Container>
                
                <CardContent>
                    <CardContent sx={{display: 'flex', justifyContent: 'center', color: 'darkgray', pb: 0}}>
                      
                      <Typography sx={{  textAlign: 'center' }} color="text.secondary">
                        Core partners
                      </Typography> 

                    </CardContent>


                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }} sx={{ 
                      display: 'flex',  justifyContent: 'center', 
                      
                      }}>

                      {partners.map((item, index) => {
                        return(
                          <Grid item xs={4} sm={4} md={3} lg={2} xl={2} 
                            key={item.title} 
                            sx={{
                             
                            display: 'flex', justifyContent: 'center', 
                            }}
                            >

                            

                            <Card sx={{ boxShadow:'none', display: 'flex', justifyContent: 'center',  }}>

                              <br />
                              <CardContent sx={{height: {xs: '80px', sm: '80px', md: '80px', lg: '80px', xl: '80px'} }} >
                              <Box sx={{
                              
                            
                            }}>

                                <BootstrapTooltip title={item.name}>
                                  <Link 

                                    component='a'                                     
                                    href={item.address} 
                                    color='secondary'
                                    target="_blank"
                                    rel="noreferrer"
                                    underline='none'
                                    >
                                      <img
                                        src={require('../img/home/' +
                                        item.img +
                                        '.svg')} alt='foto' className='about-img'
                                      />

                                  </Link>
        
                                </BootstrapTooltip>

                                
                              </Box>


                              
                                
                              </CardContent>

                            </Card>
                            
                            <br />
                            
                            
                          </Grid>



                        );
                      })}
                    
                      
                    </Grid>               
                </CardContent>
              </Container>        


          </Card>
      </Container>
      <br />                    
      <br />                    
      </section>





    
      

      


















    </div>  

    
  )
}

export default About