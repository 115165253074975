import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Typography, Box, Paper, Grid, Container, Card, CardContent, CardActions
, Button, IconButton} from '@mui/material';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import { BrowserRouter,
  Routes,
  Switch,
  Route,
  NavLink,
  Link} from "react-router-dom";
// import chainCard from '../cards/chainCard';
// import chainGrid from '../cards/chainGrid';
// import compontentCard from '../cards/compontentCard';
// import compontentGrid from '../cards/compontentGrid';
// import serviceCard from '../cards/serviceCard';
// import serviceGrid from '../cards/serviceGrid';


import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';

import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';


import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CADLogo from '../vectors/CADLogo';
import TaeLogo from '../vectors/TaeLogo';
import SelectedBtnContext from '../providers/SelectedBtnProvider';
// import somelogo from '../img/1906.jpg'
// import lg from '../img/home/geo_lg.jpg'
// import tae from '../img/home/geo_tae.jpg'
// import cad from '../img/home/geo_cad.jpg'

import ge from '../img/home/geo_small.jpg';
import dz from '../img/home/CAD.svg';
import lg from '../img/home/LG.svg';
import ta from '../img/home/TAE.svg';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { purple } from '@mui/material/colors';




const itemData1 = [
  {
    img: 'cha1',
    title: 'chain1',
  },
  {
    img: 'cha2',
    title: 'chain2',
  },
  {
    img: 'cha3',
    title: 'chain3',
  },
  {
    img: 'cha4',
    title: 'chain4',
  },
  {
    img: 'cha5',
    title: 'chain5',
  },
  {
    img: 'cha6',
    title: 'chain6',
  },
  {
    img: 'cha7',
    title: 'chain7',
  },
  {
    img: 'cha8',
    title: 'chain8',
  },
  {
    img: 'cha9',
    title: 'chain9',
  },
  {
    img: 'cha0',
    title: 'chain0',
  },
  
    
];
const itemData2 = [
  {
    img: 'tech_emp',
    title: 'compo1',
  },
  {
    img: 'tech_emp',
    title: 'compo2',
  },
  {
    img: 'tech_emp',
    title: 'compo3',
  },
  {
    img: 'tech_emp',
    title: 'compo4',
  },
  
    
];
const itemData3 = [
  {
    img: 'galv',
    title: 'serv1',
  },
  {
    img: 'cnc',
    title: 'serv2',
  },
  {
    img: 'ht',
    title: 'serv3',
  },
  
  
    
];
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

// const purplex = purple[500];

function Home() {
  
  const {selectedBtn, setSelectedBtn } = useContext(SelectedBtnContext);
  
  const [expanded1, setExpanded1] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [expanded3, setExpanded3] = React.useState(false);
  
  const handleExpandClick1 = () => {
    setExpanded1(!expanded1); 
  };
  const handleExpandClick2 = () => {
    setExpanded2(!expanded2); 
  };
  const handleExpandClick3 = () => {
    setExpanded3(!expanded3); 
  };
  
  const scrollChains = useRef(null);
  // const executeScrollChains = () => scrollChains.current.scrollIntoView({
  
//     behavior: "smooth",
//     block: "end",
//     inline: "nearest"
  
// });  
  

// const [variant, setVariant] = React.useState('text');
const [color, setColor] = React.useState('secondary');
  
const onHoverOver = () => {
//  setVariant('outlined');
 setColor('tae')
};
const onHoverOut = () => {
//  setVariant('text');
 setColor('secondary')
};
    

  
  
  
  return (
    
  <div>
    <section>

      <Container>
        <Card sx={{ minWidth: 275, boxShadow:'none'}}>
          <CardContent>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
            
            </Typography>
            
            <Typography variant="h4" component="div">
              Welcome back!
              {/* Excellence of metalworking */}
            </Typography>
          
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
             We are happy to take care of the Metalworking for you
              {/* Boost up your business with Tae Hwa Poland's wide range of end products, components and services */}
            {/* While dealing with metalworking, we at Tae Hwa Poland are supporting various types of industry by offering a great range of end products, components and services */}  
            </Typography>
          </CardContent>

          <Container>
           
            <CardActions sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>

                {/* <Button 
                size="small" 
                color='tae'
                variant="outlined" 
                sx={{
                    mr:'4px', ml:'4px',
                    // "&:hover": {
                    //   boxShadow: '0px 2px 1px -1px rgb(238 23 35 / 20%), 0px 1px 1px 0px rgb(238 23 35 / 14%), 0px 1px 3px 0px rgb(238 23 35 / 12%)',
                    //   "&& .hvr":
                    //   { color: '#EE1723' }
                    // } ,
                    // transition: item.clicked ? 'height 667ms ease' : 'height 667ms ease',
                    //           animation: item.clicked ? 'showContent 667ms ease-in-out both' : 'hideContent 667ms ease-in-out both',
                          

                  
                  
                  
                  }} 
                component={NavLink} to='/contacts'
                onClick={()=>setSelectedBtn(1)} >
                  Contact
                </Button> */}

                {/* <Box sx={{flexGrow: 1,}}><></></Box> */}

                {/* <Button size="small" color="tae" variant="outlined" 
                component={NavLink} to='/about'
                sx={{ mr:'4px', ml:'4px'}}
                onClick={()=>setSelectedBtn(0)} 
                // disabled
                >
                  Company
                </Button> */}

                

            </CardActions>
            
          </Container>
          <br />
          <Container>
          
          
          
            <CardContent>
              
              <Grid container rowSpacing={{xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}>
              
                
                {/* CHAINS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                
                  <Card sx={{
                    "&:hover": {
                      boxShadow: '0px 2px 1px -1px rgb(238 23 35 / 20%), 0px 1px 1px 0px rgb(238 23 35 / 14%), 0px 1px 3px 0px rgb(238 23 35 / 12%)',
                      "&& .hvr":
                      { color: '#EE1723' }
                    } 
                  }}>
                    <CardHeader
                      // avatar={
                      //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                      //     R
                      //   </Avatar>
                      // }
                      // action={
                      //   <ExpandMore
                      //   expand={expanded}
                      //   onClick={handleExpandClick}
                      //   aria-expanded={expanded}
                      //   aria-label="show more"
                      // >
                      //   <ExpandMoreIcon />
                      // </ExpandMore>
                      // }
                      title="CHAINS"
                      subheader="CAD Dolzamet&trade;"
                      // className='hvr'
                    />
                    <CardMedia
                    
                      component="div"
                      height="200px"
                      image={ge}
                      alt="cadlogo"
                      sx={{ 
                        minHeight: "150px",
                        //  width: "50px" 
                      }}
                      children={
                        <div>
                          <Box 
                            sx={{
                              display: 'flex', justifyContent: 'space-around', alignContent: 'center', alignItems: 'stretch',
                              p:2, flexFlow: 'column wrap', height:"150px"
                            }}>
                            <div>
                              <img
                              src={dz} alt='foto' className='home-img1'
                              />

                            </div>

                          </Box>
                          
                        </div>
                      }
                    >
                    </CardMedia>
                      {/* <CADLogo /> */}
                    <CardContent sx={{height: {xs: '50px', sm: '50px', md: '50px', lg: '80px', xl: '80px'} }} >
                      <Typography variant="body2" color="text.secondary">
                        Comlpex manufacturing and distribution of CAD Dolzamet&trade; industrial chains
                      </Typography>
                    </CardContent>
                    <br />

                    <Collapse in={expanded1} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Box sx={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
                        {/* <Box sx={{ width: 500, height: 450, overflowY: 'scroll' }}> */}
                          <ImageList variant="masonry" cols={2} gap={8}>
                            {itemData1.map((item) => (
                              <ImageListItem key={item.title}>
                                <img
                                  src={require('../img/chains/' +
                                  item.img +
                                  '.jpg')}
                                  id={item.title}
                                  // src={item.img}
                                  // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                  alt={item.title}
                                  loading="lazy"
                                />
                              </ImageListItem>
                              
                            ))}
                            
                          </ImageList>
                        </Box>    
                      </CardContent>
                    </Collapse>
                    <CardActions disableSpacing>
                      {/* <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                      </IconButton>
                      <IconButton aria-label="share">
                        <ShareIcon />
                      </IconButton> */}
                      {/* <div >Element to scroll to</div>  */}
                      <Button size="small"  color={color}
                      variant='text' 
                      onMouseOver={()=>{onHoverOver()}}
                      onMouseOut={()=>{onHoverOut()}}
                      //  component={NavLink} to='/about'
                      // onClick={()=>setSelectedBtn(0)} 
                      ref={scrollChains}
                      component={NavLink} to='/chains'
                      onClick={()=>setSelectedBtn(2)} 
                      className='hvr'
                      // disabled
                      >VIEW CHAINS</Button>
                      <ExpandMore
                        expand={expanded1}
                        // variant={variant}
                        color={color}
                        onMouseOver={()=>{onHoverOver()}}
                        onMouseOut={()=>{onHoverOut()}}
                        onClick={()=> {handleExpandClick1()}}
                        // onClick={()=> {handleExpandClick1();executeScrollChains()}}
                        aria-expanded={expanded1}
                        aria-label="show more"
                        
                      >
                        <ExpandMoreIcon className='hvr'color='secondary' />
                        {/* {
                          expanded1 ?
                          <ExpandMoreIcon className='btn'color='secondary' 
                          sx={{"&:hover": { color: '#EE1723' }}} 
                          />
                          :
                          <ExpandMoreIcon className='btn'color='secondary' 
                          sx={{"&:hover": { color: '#EE1723' }}} 
                          />
                        } */}
                      </ExpandMore>
                    </CardActions>
                    
                  </Card>
                    
                    
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    
                  {/* COMPONENTS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */} 
                  <Card sx={{
                    "&:hover": {
                      boxShadow: '0px 2px 1px -1px rgb(238 23 35 / 20%), 0px 1px 1px 0px rgb(238 23 35 / 14%), 0px 1px 3px 0px rgb(238 23 35 / 12%)',
                      "&& .hvr":
                      { color: '#EE1723' }
                    } 
                  }}>
                    <CardHeader
                      // avatar={
                      //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                      //     R
                      //   </Avatar>
                      // }
                      // action={
                      //   <ExpandMore
                      //   expand={expanded}
                      //   onClick={handleExpandClick}
                      //   aria-expanded={expanded}
                      //   aria-label="show more"
                      // >
                      //   <ExpandMoreIcon />
                      // </ExpandMore>
                      // }
                      title="COMPONENTS"
                      subheader="LG Electronics"
                    />
                    <CardMedia
                      component="div"
                      height="200px"
                      image={ge}
                      alt="cadlogo"
                      sx={{ 
                        minHeight: "150px",
                        //  width: "50px" 
                      }}
                      children={
                        <div>
                          <Box 
                            sx={{
                              display: 'flex', justifyContent: 'space-around', alignContent: 'center', alignItems: 'stretch',
                              p:2, flexFlow: 'column wrap', height:"150px"
                            }}>
                            <div>
                              <img 
                              src={lg} alt='foto' className='home-img2'
                              />

                            </div>

                          </Box>
                          
                        </div>
                      }
                    >
                    </CardMedia>
                      {/* <CADLogo /> */}
                    <CardContent sx={{height: {xs: '50px', sm: '50px', md: '50px', lg: '80px', xl: '80px'} }} >
                      <Typography variant="body2" color="text.secondary">
                        Contract manufacturing of Home Appliance semi-products in supply chain for LG Electronics 
                      </Typography>
                    </CardContent>
                    <br />
                    <Collapse in={expanded2} timeout="auto" unmountOnExit>
                      <CardContent  >
                        
                        <Box sx={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
                        {/* <Box sx={{ width: 500, height: 450, overflowY: 'scroll' }}> */}
                          <ImageList variant="masonry" cols={2} gap={8}>
                            {itemData2.map((item) => (
                              <ImageListItem key={item.title}>
                                <img
                                  src={require('../img/compo/' +
                                  item.img +
                                  '.jpg')}
                                  alt={item.title}
                                  loading="lazy"
                                />
                              </ImageListItem>
                            ))}
                          </ImageList>
                        </Box>    
                      </CardContent>
                    </Collapse>
                    <CardActions disableSpacing>
                      {/* <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                      </IconButton>
                      <IconButton aria-label="share">
                        <ShareIcon />
                      </IconButton> */}
                      <Button size="small"  color={color} variant='text'
                      // sx={{"&:hover": { color: '#EE1723' }}}
                      //  component={NavLink} to='/about'
                      // onClick={()=>setSelectedBtn(0)} 
                      disabled
                      onMouseOver={()=>{onHoverOver()}}
                      onMouseOut={()=>{onHoverOut()}}
                      className='hvr'
                      // component={NavLink} to='/components'
                      onClick={()=>setSelectedBtn(3)} 
                      >VIEW COMPONENTS</Button>
                      <ExpandMore
                        expand={expanded2}
                        onClick={handleExpandClick2}
                        aria-expanded={expanded2}
                        aria-label="show more"
                        color={color}
                        onMouseOver={()=>{onHoverOver()}}
                        onMouseOut={()=>{onHoverOut()}}
                      >
                        <ExpandMoreIcon color='secondary' className='hvr'/>
                        {/* {
                          expanded2 ?
                          <ExpandMoreIcon color='secondary' sx={{"&:hover": { color: '#EE1723' }}}/>
                          :
                          <ExpandMoreIcon color='secondary' sx={{"&:hover": { color: '#EE1723' }}}/>
                        } */}
                      </ExpandMore>
                    </CardActions>
                    
                  </Card>
                    
                    
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    
                    {/* SERVICES !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
                  <Card sx={{
                    "&:hover": {
                      boxShadow: '0px 2px 1px -1px rgb(238 23 35 / 20%), 0px 1px 1px 0px rgb(238 23 35 / 14%), 0px 1px 3px 0px rgb(238 23 35 / 12%)',
                      "&& .hvr":
                      { color: '#EE1723' }
                    } 
                  }}>
                    <CardHeader
                      // avatar={
                      //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                      //     R
                      //   </Avatar>
                      // }
                      // action={
                      //   <ExpandMore
                      //   expand={expanded}
                      //   onClick={handleExpandClick}
                      //   aria-expanded={expanded}
                      //   aria-label="show more"
                      // >
                      //   <ExpandMoreIcon />
                      // </ExpandMore>
                      // }
                      title="SERVICES"
                      subheader="Tae Hwa Poland"
                    />
                    <CardMedia
                      component="div"
                      height="200px"
                      image={ge}
                      alt="cadlogo"
                      sx={{ 
                        minHeight: "150px",
                        //  width: "50px" 
                      }}
                      children={
                        <div>
                          <Box 
                            sx={{
                              display: 'flex', justifyContent: 'space-around', alignContent: 'center', alignItems: 'stretch',
                              p:2, flexFlow: 'column wrap', height:"150px"
                            }}>
                            <div>
                              <img 
                              src={ta} alt='foto' className='home-img3'
                              />

                            </div>

                          </Box>
                          
                        </div>
                      }
                    >
                    </CardMedia>
                      {/* <CADLogo /> */}

                    <CardContent sx={{height: {xs: '50px', sm: '50px', md: '50px', lg: '80px', xl: '80px'} }} >
                      <Typography variant="body2" color="text.secondary">
                      Previously under the banner of BMC POLAND: Galvanizing &sdot; CNC Laser &sdot; Heat-Treatment
                      </Typography>
                    </CardContent>
                    <br />
                    <Collapse in={expanded3} timeout="auto" unmountOnExit>
                      <CardContent>
                        
                        <Box sx={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
                        {/* <Box sx={{ width: 500, height: 450, overflowY: 'scroll' }}> */}
                          <ImageList variant="masonry" cols={2} gap={8}>
                            {itemData3.map((item) => (
                              <ImageListItem key={item.title}>
                                <img
                                  // src={`${item.img}?w=248&fit=crop&auto=format`}
                                  // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                  src={require('../img/services/' +
                                  item.img +
                                  '.jpg')}
                                  alt={item.title}
                                  loading="lazy"
                                />
                              </ImageListItem>
                            ))}
                          </ImageList>
                        </Box>    
                      </CardContent>
                    </Collapse>
                    <CardActions disableSpacing>
                      {/* <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                      </IconButton>
                      <IconButton aria-label="share">
                        <ShareIcon />
                      </IconButton> */}
                      <Button size="small"  color={color} variant='text' 
                      // sx={{"&:hover": { color: '#EE1723' }}}
                      // component={NavLink} to='/services'
                      onClick={()=>setSelectedBtn(3)} 
                      disabled
                      onMouseOver={()=>{onHoverOver()}}
                      onMouseOut={()=>{onHoverOut()}}
                      className='hvr'
                      >VIEW SERVICES</Button>
                      <ExpandMore
                        expand={expanded3}
                        onClick={handleExpandClick3}
                        aria-expanded={expanded3}
                        aria-label="show more"
                        color={color}
                        onMouseOver={()=>{onHoverOver()}}
                        onMouseOut={()=>{onHoverOut()}}
                      >
                        <ExpandMoreIcon color='secondary' className='hvr' />
                        {/* {
                          expanded3 ?
                          <ExpandMoreIcon color='secondary' sx={{"&:hover": { color: '#EE1723' }}}/>
                          :
                          <ExpandMoreIcon color='secondary' sx={{"&:hover": { color: '#EE1723' }}}/>
                        } */}
                      </ExpandMore>
                    </CardActions>
                    
                  </Card>
                    
                </Grid>
            <br />
            <br />
            <br />
              
              </Grid>

            </CardContent>

          </Container>
           







          
          <br />
          <br />
          <Container>

            {/* <Card sx={{ minWidth: 275 }}> */}
              <CardContent sx={{display: 'flex', justifyContent: 'center', color: 'darkgray'}}>
                {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Why partner with TAE HWA POLAND?
                </Typography> */}
                    <Typography sx={{ mb: 1.5, textAlign: 'center' }} color="text.secondary">
                      {/* Profitable, efficient &amp; reliable solutions for you and your business */}
                      {/* let's get to know each other better */}
                      Providing
                    </Typography>  
                {/* <Typography variant="subtitle" component="div" sx={{fontStretch: 'ultra-condensed'}}>
                  Profitable, efficient &amp; reliable solutions for you and your business
                </Typography> */}
                <br />
                {/* <Typography variant="h5" component="div">
                  solutions for you and your business
                </Typography> */}
                
              </CardContent>
            {/* <Container> */}

              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    
                    
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>

                        
                        
                        <Card>
                          <CardContent sx={{height: {xs: '130px', sm: '130px', md: '150px', lg: '150px', xl: '150px'} }} >
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                              <HistoryOutlinedIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></HistoryOutlinedIcon> 
                              <Typography variant='subtitle2' >
                                
                                Years of Experience
                              </Typography>

                            </Box>
                            
                            <br />
                            <Typography variant='body2'>
                            We develop in industry as an indirect successor of "Agromet-Dolzamet" founded in 1946
                            </Typography>
                            {/* <Typography variant="h5" component="div">
                              solutions for you and your business
                            </Typography> */}
                            
                          </CardContent>

                        </Card>
                        <br />
                        
                        
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        
                        <Card>
                          <CardContent sx={{height: {xs: '130px', sm: '130px', md: '150px', lg: '150px', xl: '150px'} }} >
                              <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <BiotechOutlinedIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></BiotechOutlinedIcon> 
                                
                                <Typography variant='subtitle2'>
                                  New Technologies
                                </Typography>
        
                              </Box>
                            
                            <br />
                            <Typography variant='body2'>
                              Grounded with tradition we constantly implement the newest technology solutions
                            </Typography>
                            
                            
                          </CardContent>

                        </Card>
                        <br />
                        
                        
                        
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        
                      <Card>
                          <CardContent sx={{height: {xs: '130px', sm: '130px', md: '150px', lg: '150px', xl: '150px'} }} >
                              <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <GppGoodOutlinedIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></GppGoodOutlinedIcon> 
                                
                                <Typography variant='subtitle2'>
                                Dedication to Quality
                                </Typography>
        
                              </Box>
                            
                            <br />
                            <Typography variant='body2'>
                              We pay the highest priority to ensure we deliver the best quality possible
                            </Typography>
                            
                            
                          </CardContent>

                        </Card>
                        <br />
                        
                        
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        
                        <Card>
                          <CardContent sx={{height: {xs: '130px', sm: '130px', md: '150px', lg: '150px', xl: '150px'} }} >
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                              <SupportAgentOutlinedIcon color='tae' sx={{m: '3px', fontSize: '1rem'}}></SupportAgentOutlinedIcon> 
                              
                              <Typography variant='subtitle2'>
                              Flexible approach
                              </Typography>

                            </Box>
                            
                            <br />
                            <Typography variant='body2'>
                              Our products and services can be always a subject of client's customisation
                            </Typography>
                            
                            
                          </CardContent>

                        </Card>
                        <br />
                        
                        
                    </Grid>
                  
                  </Grid>
              {/* </Container> */}




            {/* </Card> */}
              
                
              
              
              <br />
                

                
          </Container>

        </Card>



            
      </Container>
    

    </section>
    
    
    <br /><br />

    
  </div>
  );
}

export default Home;