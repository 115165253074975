import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff"
    },
    secondary: {
      main: "#000"
    },
    tae: {
      main: "#EE1723"
    }
  },
  typography: {
    isHeadLink: {
      color: '#EE1723',
      fontSize: '.83rem'
    },
    onHoverHeadLink: {
      color: '#000',
      fontSize: '.83rem'
    }

  },
  breakpoints: {
    values: {
      xs: 300,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  


});
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />

    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
