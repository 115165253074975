import React from 'react';
import Home from '../contents/Home';
import About from '../contents/About';
import Contacts from '../contents/Contacts';
import Chains from '../contents/Chains';
import Certifications from '../contents/Certifications';

import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';

import Footer from './Footer';
import Meta from './Meta'
import { Box , Container } from '@mui/material';
import { BrowserRouter,
        Routes,
        Switch,
        Route,
        Link} from "react-router-dom";



import { useEffect, useState } from "react";


function Body() {

    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
          if (window.pageYOffset > 300) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        });
      }, []);

      // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  return (
    <Box sx={{
        
        // alignItems: 'cener', justifyContent: 'center'
        }}>
        <Box sx={{minHeight: 'calc(100vh - 130px)', 
        // width: 'calc(100% - 5em)'
        width: '100%'
        
        }}>
            {/* <BrowserRouter> */}
                <Routes>
                
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/contacts" element={<Contacts />} />
                    <Route path="/about" element={<About />} />

                    <Route path="/certifications" element={<Certifications />} />
                    <Route path="/chains" element={<Chains />} />
                    
                    {/* <Route path="/components" element={<Components />} />
                    <Route path="/services" element={<Services />} />  */}
                
                </Routes>
            
            {/* </BrowserRouter> */}
            {showButton && (
                
                <Fab color="primary" size="small" aria-label="up" onClick={scrollToTop} 
                sx={{
                    position: 'fixed',  bottom: '10vh', 
                    left: '10vw'
                    // left: '48.7vw'
                }}
                >
                    <NavigationIcon />
                </Fab>
            )

            }


        </Box>   
        
        <Box sx={{
            ml:3, mr:3
            // pt: "3px"   
        }} >
            <Footer /> 
        </Box>

    </Box>
  )
}

export default Body